import { Table, TableContainer, Paper } from "@material-ui/core"
import clsx from "clsx"
import "../../customCSS/scrollbar.css"

type StyledTableProps = {
  classNames?: {
    container?: string | string[]
    table?: string | string[]
  }
  padding?: 'checkbox' | 'none' | 'default'
  size?: 'small' | 'medium'
  stickyHeader?: boolean
	children?: any
}

export function StyledTable(props: StyledTableProps): JSX.Element {
	return (
		<TableContainer
			className={clsx(props.classNames?.container)}
			component={Paper}
		>
			<Table
				className={clsx(props.classNames?.table)}
        padding={props.padding}
				size={props.size}
        stickyHeader={props.stickyHeader}
			>
				{props.children}
			</Table>
		</TableContainer>
	)
}

export function ScrollableStyledTable(props: Omit<StyledTableProps, "stickyHeader">): JSX.Element {
  return <StyledTable
    classNames={{
      container: clsx("scrollbar", "scrollbar-overlay", props.classNames?.container),
      table: props.classNames?.table,      
    }}
    padding={props.padding}
    size={props.size}
    stickyHeader
  >
    {props.children}
  </StyledTable>
}