import React from 'react'

type TableProps = React.PropsWithChildren<{}>

export function Table(props: TableProps) {
  return <table style={{
    borderCollapse: 'collapse'
  }}>
    {props.children}
  </table>
}

type TableHeadProps = React.PropsWithChildren<{}>

export function TableHead(props: TableHeadProps) {
  return <thead>{props.children}</thead>
}

type TableBodyProps = React.PropsWithChildren<{}>

export function TableBody(props: TableBodyProps) {
  return <tbody>{props.children}</tbody>
}

type TableFootProps = React.PropsWithChildren<{}>

export function TableFoot(props: TableFootProps) {
  return <tfoot>{props.children}</tfoot>
}

type TableRowProps = React.PropsWithChildren<{
  shadedAt?: 'even' | 'odd' | 'none'
}>

export function TableRow(props: TableRowProps) {
  let className
  switch (props.shadedAt) {
    case 'even': className = 'shadedEven'; break
    case 'odd': className = 'shadedOdd'; break
  }
  return <tr className={className}>{props.children}</tr>
}

type CellProps = React.PropsWithChildren<{
  style?: React.CSSProperties
}>

type TableHeadCellProps = CellProps

export function TableHeadCell(props: TableHeadCellProps) {
  return <th style={{
    padding: '2mm',
    border: '1px solid black',
    ...props.style
  }}>{props.children}</th>
}

type TableCellProps = CellProps & {
  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
}

export function TableCell(props: TableCellProps) {
  return <td style={{
    textAlign: props.align,
    padding: '1mm',
    border: '1px solid black',
    ...props.style
  }}>{props.children}</td>
}