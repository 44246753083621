import { SpecialChars } from ".."

type Props = {
  id: string | undefined
}

export function TaxID(props: Props): JSX.Element {
  const formattedID = (props.id != null)
    ? props.id.replace(/(.{4})/g, '$1 ')
    : SpecialChars.emDash
  return <>{formattedID}</>
}