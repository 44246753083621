import { Divider, Button, Typography, DialogActions, makeStyles, Paper } from "@material-ui/core"
import { ParsedError, parseError } from "./parseError"

const useStyle = makeStyles(theme => ({
  wrapper: {
    maxWidth: '50%',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  paper: {
    maxWidth: 'max-content',
    margin: 'auto',
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    height: 'min-content',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textAlign: 'center',
  },
  content: {
    margin: theme.spacing(2),
  },
  divider: {
    width: '50%',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

type Props = {
  error: Error | Error[]
  onReload: () => void
}

export function LoadingError(props: Props): JSX.Element {
  const classes = useStyle()
  let parsedErrors: ParsedError[] = []
  if (props.error instanceof Error) {
    parsedErrors = parseError(props.error)
  } else {
    parsedErrors = parsedErrors.concat(
      ...props.error.map(err => parseError(err))
    )
  }
  return (
    <div className={classes.wrapper}>
      {
        parsedErrors.map(err => (
          <Paper className={classes.paper}>
            <div className={classes.head}>
              <Typography variant="h5">{err.title}</Typography>
            </div>
            <div className={classes.content}>
              {err.content}
              {
                (err.details === undefined)
                  ? undefined
                  : <>
                    <Divider className={classes.divider} />
                    <Typography variant="h6">
                      {err.details.title}
                    </Typography>
                    <Typography variant="body2">
                      {err.details.content}
                    </Typography>
                  </>
              }
            </div>
          </Paper>
        ))
      }
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onReload}
        >
          Seite neuladen
        </Button>
      </DialogActions>
    </div>
  )
}