import { TableCell, TableHead, TableBody, makeStyles } from "@material-ui/core";
import useTitle from "../TitleProvider";
import { StyledTable, TableHeadCell, HoveredTableRow, LoadingDialog } from "../customUI";
import { grey } from "@material-ui/core/colors";
import { format } from "../util/dateFnsWrapper";
import { ParsedEmployeeData, useGetEmployees } from "./getEmployees";
import { Interval } from "../customUI/formatting";
import { useState } from "react";
import { LoadingError } from "../Error/LoadingError";

const useStyle = makeStyles(theme => ({
	tableContainer: {
		width: 'max-content',
	},
	greyedCell: {
		color: grey[400],
	},
}))

export function Home() {
	const classes = useStyle()
	const title = useTitle()
	title?.setTitle('Übersicht')

	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)

	const employees = useGetEmployees()
	if (employees.isLoading() !== loadingDialogOpen) {
		setLoadingDialogOpen(employees.isLoading())
	}
	if (employees.data.isError()) {
		return (
			<LoadingError
				error={employees.data.error as Error}
				onReload={() => employees.refetchQuery()}
			/>
		)
	}

	const formatEmployees = (employees: ParsedEmployeeData[], active?: boolean): JSX.Element[] => {
		return employees
			.map((employee) => {
				const className = (active ?? false)
					? undefined
					: classes.greyedCell
				const date = (employee.leavingDate !== undefined)
					? format(employee.leavingDate ?? 0, "dd.MM.yyyy")
					: "—"
				const staffNumber = (employee.staffNumber)
					? (employee.staffNumber + "").padStart(5, "0")
					: undefined
				return (
					<HoveredTableRow
						key={"HomeEmployee" + employee.uuid}
					>
						<TableCell
							className={className}
							align="right"
						>{staffNumber}</TableCell>
						<TableCell
							className={className}
						>{employee.name}</TableCell>
						<TableCell
							className={className}
							align="right"
						>
							<Interval
								minutes={employee.overtime}
							/>
						</TableCell>
						<TableCell
							className={className}
							align="right"
						>{date}</TableCell>
					</HoveredTableRow>
				)
			})
	}

	return <>
		<LoadingDialog open={loadingDialogOpen} />
		<StyledTable
			classNames={{
				container: classes.tableContainer
			}}
		>
			<TableHead>
				<HoveredTableRow>
					<TableHeadCell>Personalnummer</TableHeadCell>
					<TableHeadCell>Name</TableHeadCell>
					<TableHeadCell>Überstunden</TableHeadCell>
					<TableHeadCell>Austrittsdatum</TableHeadCell>
				</HoveredTableRow>
			</TableHead>
			<TableBody>
				{formatEmployees(employees.data.value?.active ?? [], true)}
				{formatEmployees(employees.data.value?.inactive ?? [])}
			</TableBody>
		</StyledTable>
	</>
}