import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"

export function getGQLErrors(error: Error): ReadonlyArray<GraphQLError> {
	if (error instanceof ApolloError) {
		return error.graphQLErrors
	}
	return []
}

export function hasGQLErrorExtension(
	error: Error,
	extensionName: string,
): boolean {
	if (error instanceof ApolloError) {
		return (error.graphQLErrors
			.filter(err => err.extensions?.name === extensionName)
			.length
		) > 0
	}
	return false
}

export function isNetworkError(error: Error): boolean {
	if (error instanceof ApolloError) {
		return error.networkError != null
	}
	return false
}

type GraphQLExtension<Data> = {
	data: Data
	name: string
}

export type InvalidParameters = {
	parameters: {
		errorName: string
		errorMessage: string
		name: string
		value: any
	}[]
}

export function getInvalidParameters(error: Error): InvalidParameters {
	if (error instanceof ApolloError) {
		const values = error.graphQLErrors.find(e => e.extensions?.name === 'InvalidParameter')
		return (values?.extensions as GraphQLExtension<InvalidParameters>).data
	}
	return { parameters: [] }
}