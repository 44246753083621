import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { Maybe } from "../../maybe";
import { refetchGetEmployeesQuery } from "../getEmployees";

const deleteEmploymentPeriodMutation = gql`
    mutation delteEmploymentPeriod(
        $id: ID!
    ) {
        employment_period_delete(
            employment_period_id: $id
        )
    }
`

type Params = {
    id: string
}

type Result = {
    employment_period_delete: boolean
}

export function useDeleteEmploymentPeriod(
    options?: MutationHookOptions<Result, Params>
): (data: Params) => Promise<Maybe<boolean>> {
    const [mutation] = useMutation<Result, Params>(
        deleteEmploymentPeriodMutation,
        options,
    )

    return async (data: Params) => {
        try {
            const result = await mutation({
                variables: data,
                refetchQueries: [refetchGetEmployeesQuery]
            })
            if (result.data != null) {
                return Maybe.Just(result.data.employment_period_delete)
            }
            return Maybe.None()
        } catch (e: any) {
            return Maybe.Error(e)
        }
    }
}