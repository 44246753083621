import { gql, useMutation } from "@apollo/client";
import BigNumber from "bignumber.js";
import { HolidayType } from "../../../gql";
import { Maybe } from "../../../maybe";
import { refetchGetEmployeesQuery } from "../../getEmployees";

const editHolidayMutation = gql`
	mutation editHoliday(
		$id: ID!,
		$type: holiday_type,
		$amount: Decimal,
		$date: DateTime,
		$comment: String
	) {
		holiday_edit(
			holiday_id: $id,
			type: $type,
			amount_of_day: $amount,
			date: $date,
			comment: $comment
		) {
			id
		}
	}
`

type Params = {
  id: string
  type?: HolidayType
  amount?: BigNumber
  date?: Date
  comment?: string
}

type Result = {
  holiday_edit: { id: string }
}

export function useEditHoliday(refetchDate: Date):
  (data: Params) => Promise<Maybe<boolean>> {
  const [mutation] = useMutation<Result, Params>(
    editHolidayMutation,
  )

  return async (data: Params) => {
    try {
      const mutationResult = await mutation({
        variables: data,
        refetchQueries: [refetchGetEmployeesQuery(refetchDate)],
      })
      if (mutationResult.data != null) {
        return Maybe.Just(true)
      }
      return Maybe.None()
    } catch(e: any) {
      return Maybe.Error(e)
    }
  }
}