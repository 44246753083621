import { useState } from "react";

function useArrayState<T>(initial: T[]) {
	const [state, setState] = useState<T[]>(initial)

	const setStateAt = (i: number, val: T) => {
		const oldState = state
		oldState[i] = val
		setState([...oldState])
	}

	const onItemInsert = (index: number, val: T) => {
		if (state[index] === undefined) {
			var oldState = state
			oldState[index] = val
			setState([...oldState])
		}
	}

	const onItemDelete = (index: number) => {
		if (state[index] !== undefined) {
			var oldState = state
			oldState.splice(index, 1)
			setState([...oldState])
		}
	}

	return { state, setStateAt, onItemInsert, onItemDelete }
}

export default useArrayState