type PageProps = React.PropsWithChildren<{
	orientation?: 'portrait' | 'landscape'
}>

export function PageA4(props: PageProps) {
	let width, height, orientation
	switch (props.orientation) {
		case 'landscape':
			orientation = 'landscape'
			width = '297mm'
			height = '210mm'
			break
		default:
			orientation = 'protrait'
			width = '210mm'
			height = '297mm'
	}
	const style = `
		@page {
			size: A4 ${orientation};
			margin: 0;
		}
		body {
			width: ${width};
			height: ${height};
			padding-left: 25mm;
			padding-right: 25mm;
			padding-top: 15mm;
			padding-bottom: 15mm;
		}
		* {
			font-family: arial;
		}
		tr.shadedEven:nth-child(even), tr.shadedOdd:nth-child(odd) {
			background-color: rgba(0, 0, 0, 0.10)
		}
	`

	return <>
		<style>{style}</style>
		{props.children}
	</>
}