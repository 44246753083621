import { makeStyles } from "@material-ui/core";
import { LoadingDialog } from "../customUI";
import useTitle from "../TitleProvider";
import { EmployeeSelect } from "../customUI/EmployeeSelect/EmployeeSelect";
import { endOfMonth, format, isValid, parse, startOfMonth } from "date-fns";
import Summary from "./Summary"
import { useHistory, useParams } from "react-router-dom";
import { Employee, useGetEmployee } from "./getEmployee";
import { useGetDetails } from "./getDetails";
import { KeyboardMonthPicker } from "../util/dateFnsWrapper";
import { isNetworkError } from "../Error/gqlErrorUtil";
import { LoadingError } from "../Error/LoadingError";
import WorkingPeriods from "./WorkingPeriods";
import BigNumber from "bignumber.js";
import MonthSummary from "./MonthSummary";

const useStyle = makeStyles(theme => ({
	pageWrapper: {
		display: 'grid',
		gridTemplateColumns: '1fr 2fr 1fr',
		gap: theme.spacing(2),
	},
	center: {
		gridColumn: 2,
	},
	controlButtonGrid: {
		width: 'max-content',
		display: 'grid',
		gridTemplateColumns: 'max-content max-content',
		gap: theme.spacing(2),
		margin: 'auto',
	},
	sidePanelWrapper: {
		display: 'grid',
		gridAutoRows: 'max-content',
		gap: theme.spacing(2),
	},
}))

type URLParams = {
	employeeUUID?: string
	month?: string
}

export default function Times() {
	const title = useTitle()
	const classes = useStyle()

	const { month: urlMonth, employeeUUID: urlUUID } = useParams<URLParams>()
	const history = useHistory()
	let pushHistory = false

	const fetchedEmployee = useGetEmployee({ id: urlUUID })
	if (fetchedEmployee.data.isError()) {
		if (!isNetworkError(fetchedEmployee.data.error as Error)) {
			pushHistory = true
		}
	}

	let parsedDate = parse(urlMonth ?? "", "yyyy-MM", new Date())
	if (!isValid(parsedDate)) {
		parsedDate = new Date()
		pushHistory = true
	}

	const fetchedDetails = useGetDetails(
		{
			employeeUUID: fetchedEmployee.data.value?.uuid,
			month: parsedDate,
		}
	)

	if (pushHistory) {
		history.push(`/times/${format(new Date(), "yyyy-MM")}`)
		return <></>
	}

	if (fetchedEmployee.data.isError() || fetchedDetails.data.isError()) {
		const errors: Error[] = []
		if (fetchedEmployee.data.isError()) {
			errors.push(fetchedEmployee.data.error as Error)
		}
		if (fetchedDetails.data.isError()) {
			errors.push(fetchedDetails.data.error as Error)
		}
		return <LoadingError
			error={errors}
			onReload={() => {
				fetchedEmployee.refetchQuery()
				fetchedDetails.refetchQuery()
			}}
		/>
	}

	if (fetchedEmployee.data.isJust()) {
		const employee = fetchedEmployee.data.value as Employee
		const newTitle = `Zeiten - ${employee.name}`
		const staffNumber = (employee.staffNumber !== undefined)
			? " - " + (employee.staffNumber + "").padStart(5, '0')
			: ""
		const newHeadline = `${newTitle}${staffNumber}`
		title?.setTitle(newHeadline, newTitle)
	} else {
		title?.setTitle("Zeiten")
	}

	const employeeValue = fetchedEmployee.data.value
	const detailsValue = fetchedDetails.data.value
	const summaryCollapsed = detailsValue === undefined
	const monthSummaryCollapsed = (detailsValue === undefined) ||
		(
			(detailsValue?.payouts.isEqualTo(0)) &&
			(detailsValue?.daysOfIllness === 0) &&
			(detailsValue?.holidays.intervals.length === 0)
		)
	return (
		<>
			<LoadingDialog open={fetchedEmployee.isLoading() || fetchedDetails.isLoading()} />
			<div className={classes.pageWrapper}>

				<div className={classes.center}>
					<div className={classes.controlButtonGrid}>
						<EmployeeSelect
							currentEmployeeUUID={fetchedEmployee.data.value?.uuid ?? ""}
							activePeriod={{
								start: startOfMonth(parsedDate),
								end: endOfMonth(parsedDate)
							}}
							onSubmit={uuid => {
								if (uuid !== "") {
									history.push(`/times/${format(parsedDate, "yyyy-MM")}/${uuid}`)
								}
							}}
							keyboardEventInput={{
								prev: "ArrowUp",
								next: "ArrowDown",
							}}
						/>
						<KeyboardMonthPicker
							date={parsedDate}
							setDate={d => {
								const uuid = employeeValue ? `/${employeeValue.uuid}` : ""
								history.push(`/times/${format(d as Date, "yyyy-MM")}${uuid}`)
							}}
							keyboardEventInput={{
								prev: "ArrowLeft",
								next: "ArrowRight"
							}}
						/>
					</div>
					<WorkingPeriods
						displayedMonth={parsedDate}
						workingPeriods={detailsValue?.workingPeriods ?? []}
						employeeUUID={employeeValue?.uuid ?? ""}
					/>
				</div>
				<div className={classes.sidePanelWrapper}>
					<Summary
						collapsed={summaryCollapsed}
						overtime={detailsValue?.overtime ?? new BigNumber(0)}
						holidays={{
							total: detailsValue?.holidays.total ?? 0,
							left: (detailsValue?.holidays.total ?? 0) - (detailsValue?.holidays.taken ?? 0),
							taken: detailsValue?.holidays.taken ?? 0,
						}}
					/>
					<MonthSummary
						employeeUUID={urlUUID ?? ""}
						collapsed={monthSummaryCollapsed}
						currentMonth={parsedDate}
						overtime={detailsValue?.overtimeOfMonth ?? 0}
						payouts={detailsValue?.payouts ?? 0}
						daysOfIllness={detailsValue?.daysOfIllness ?? 0}
						holidays={detailsValue?.holidays.intervals ?? []}
					/>
				</div>
			</div>
		</>
	)
}