import { AppBar, Button, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer, Toolbar, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import * as Icons from '@material-ui/icons';
import { Employees } from './Employees/Employees';
import Times from './Times/Times';
import { Home } from './Home/Home';
import { Title } from './TitleProvider';
import LoginScreen from './Login/LoginScreen';
import usePersistedState from './states/persistentState';
import { IndexedDB } from './indexedDB/indexedDB';
import { Holidays } from './Holidays/Holidays';
import './customCSS/bodyScrollbar.css'
import { useLogout } from './logout';
import { useError } from './Error/ErrorProvider';
import { LoadingDialog } from './customUI';

const useStyle = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  sideMenu: {
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  sideMenuItem: {
    minWidth: '250px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    }
  },
  sideMenuLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  sideMenuFooter: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'absolute',
    bottom: 0,
  },
  contentWrapper: {
    width: 'fit-content',
    margin: 'auto',
    padding: theme.spacing(2),
  },
}))

async function testDB() {
  const db = await IndexedDB.open("test", {
    stores: {
      "test": {
        autoIncrement: true,
      },
    }
  })
  //db.createObjectStore("testerTable")
  //db.tx(tx => {
  //  console.log(await tx.putValue("test", { test: "test" }, "testkey"))
  //  console.log(await tx.putValue("test", { lala: "l" }))
  //  console.log(await tx.getValue("test", "testkey"))
  //}, "test", "readonly")

  //console.log(await db.putValue("test", { test: "test" }, "testkey"))
  //console.log(await db.putValue("test", { lala: "l" }))
  //console.log(await db.getValue("test", "testkey"))
}
testDB()

function App() {
  const classes = useStyle()
  const errorHandler = useError()

  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [loggedIn, setLoggedIn] = usePersistedState("loggedIn", false)

  const logout = useLogout()

  if (!loggedIn) {
    return (
      <LoginScreen
        onLogin={() => setLoggedIn(true)}
      />
    )
  }

  return (
    <>
      <LoadingDialog open={loadingDialogOpen} />
      <AppBar position="static">
        <Toolbar>
          <Button onClick={() => setSideMenuOpen(true)}><Icons.Menu /></Button>
          <Typography className={classes.title} variant="h6">
            <Title />
          </Typography>
          <Button onClick={async () => {
            setLoadingDialogOpen(true)
            const result = await logout()
            setLoadingDialogOpen(false)
            if (result.isError()) {
              errorHandler.pushError(result.error as Error)
            }
            if (result.value === true) {
              setLoggedIn(false)
            }
          }}><Icons.ExitToApp /></Button>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        className={classes.sideMenu}
        anchor="left"
        open={sideMenuOpen}
        onOpen={() => setSideMenuOpen(true)}
        onClose={() => setSideMenuOpen(false)}
      >
        <List>
          <Link className={classes.sideMenuLink} to="/" onClick={() => setSideMenuOpen(false)}>
            <ListItem className={classes.sideMenuItem}>
              <ListItemIcon><Icons.HomeOutlined /></ListItemIcon>
              <ListItemText primary="Übersicht" />
            </ListItem>
          </Link>
          <Link className={classes.sideMenuLink} to="/employees" onClick={() => setSideMenuOpen(false)}>
            <ListItem className={classes.sideMenuItem}>
              <ListItemIcon><Icons.PermIdentity /></ListItemIcon>
              <ListItemText primary="Angestellte" />
            </ListItem>
          </Link>
          <Link className={classes.sideMenuLink} to="/times" onClick={() => setSideMenuOpen(false)}>
            <ListItem className={classes.sideMenuItem}>
              <ListItemIcon><Icons.Alarm /></ListItemIcon>
              <ListItemText primary="Zeiten" />
            </ListItem>
          </Link>
          <Link className={classes.sideMenuLink} to="/holidays" onClick={() => setSideMenuOpen(false)}>
            <ListItem className={classes.sideMenuItem}>
              <ListItemIcon><Icons.DeckOutlined /></ListItemIcon>
              <ListItemText primary="Urlaube" />
            </ListItem>
          </Link>
        </List>
        <Typography
          className={classes.sideMenuFooter}
          variant="caption"
        >© powered by Borago</Typography>
      </SwipeableDrawer>

      <div className={classes.contentWrapper}>
        <Switch>
          <Route path="/holidays/:month?">
            <Holidays />
          </Route>
          <Route path="/employees">
            <Employees />
          </Route>
          <Route path="/times/:month?/:employeeUUID?">
            <ParamKeyWrapper
              Component={Times}
              getKey={(v: { month: string, employeeUUID: string }) => `${v.month}-${v.employeeUUID}`}
            />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </>
  )
}

function ParamKeyWrapper<T>(props: { Component: any, getKey: (v: T) => string }) {
  const { Component, getKey } = props
  const key = getKey(useParams<T>())
  return <Component key={key} />
}

export default App