import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { FC } from "react";

type Props = {
	classNames?: string | string[]
	mail: string
}

const useStyle = makeStyles(theme => ({
	defaultStyle: {
		textDecoration: "none",
		color: theme.palette.primary.main,
		'&:hover': {
			textDecoration: "underline",
		},
	},
}))

export const MailTo: FC<Props> = (props) => {
	const classes = useStyle()

	return (
		<a
			className={clsx(classes.defaultStyle, props.classNames)}
			href={`mailto:${props.mail}`}
		>{props.children ?? props.mail}</a>
	)
}