import { Button, Dialog, DialogActions, DialogTitle, TableRow, makeStyles, TableCell, TableBody, TableHead, TextField, ButtonGroup, Switch, FormControlLabel } from "@material-ui/core"
import { useState } from "react"
import { LoadingDialog } from "../dialogs"
import { TableHeadCell, ScrollableStyledTable } from "../tables"
import { ParsedEmployee, useGetEmployees } from "./getEmployees"
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { LoadingError } from "../../Error/LoadingError"
import clsx from "clsx"
import { useRegisterKeyboardInput } from "../../util/effects"

const useStyle = makeStyles(theme => ({
	header: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		padding: theme.spacing(2),
	},
	content: {
		display: 'grid',
		gridTemplateColumns: 'auto',
		gap: theme.spacing(2),
		margin: theme.spacing(2),
	},
	table: {
		height: theme.spacing(40),
	},
	row: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.getContrastText(theme.palette.secondary.light),
		},
	},
	inactiveRow: {
		color: theme.palette.grey[500],
	},
	column: {
		minWidth: "200px",
	},
	activeOnlyLabel: {
		float: "right",
	},
}))

type EmployeeHandle = (uuid: string, firstName?: string, lastName?: string) => void

type EployeeDialogProps = {
	onClose: () => void
	open: boolean
	onSubmit: EmployeeHandle
	onEnter: EmployeeHandle
	employees: ParsedEmployee[]
}

export function EmployeeDialog(props: EployeeDialogProps) {
	const classes = useStyle()

	const [searchText, setSearchText] = useState("")
	const [activeOnly, setActiveOnly] = useState(true)

	const displayedEmployees = props.employees
		.filter(employee => {
			if (activeOnly) {
				return employee.active
			}
			return true
		})
		.filter(employee => {
			const name = `${employee.name.last} ${employee.name.first}`.toLowerCase()
			const search = searchText.toLowerCase()
			return name.indexOf(search) !== -1
		}).sort((lhs, rhs) => {
			const nameLhs = `${lhs.name.last} ${lhs.name.first}`.toLowerCase()
			const nameRhs = `${rhs.name.last} ${rhs.name.first}`.toLowerCase()
			const search = searchText.toLowerCase()
			return nameLhs.indexOf(search) - nameRhs.indexOf(search)
		})

	useRegisterKeyboardInput(
		"Enter",
		() => {
			props.onEnter(
				displayedEmployees[0]?.uuid ?? "",
				displayedEmployees[0]?.name.first,
				displayedEmployees[0]?.name.last,
			)
			props.onClose()
		}
	)

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle className={classes.header}>
				Angestellten auswählen
			</DialogTitle>
			<div className={classes.content}>
				<div>
					<FormControlLabel
						className={classes.activeOnlyLabel}
						labelPlacement="start"
						label="Nur aktive Mitarbeiter"
						control={
							<Switch
								checked={activeOnly}
								onChange={() => setActiveOnly(!activeOnly)}
							/>
						}
					/>
					<TextField
						fullWidth
						autoFocus
						label="Nach Angestellten suchen..."
						onChange={(e) => setSearchText(e.target.value)}
						value={searchText}
					/>
				</div>
				<ScrollableStyledTable classNames={{ container: classes.table }}>
					<TableHead>
						<TableRow key="employeeSelectHead">
							<TableHeadCell className={classes.column}>Nachname</TableHeadCell>
							<TableHeadCell className={classes.column}>Vorname</TableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{displayedEmployees?.map(employee => (
							<TableRow
								className={classes.row}
								key={employee.uuid}
								onClick={() => {
									props.onSubmit(
										employee.uuid,
										employee.name.first,
										employee.name.last
									)
									props.onClose()
								}}
							>
								<TableCell className={clsx(
									!employee.active && classes.inactiveRow
								)}>{employee.name.last}</TableCell>
								<TableCell className={clsx(
									!employee.active && classes.inactiveRow
								)}>{employee.name.first}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</ScrollableStyledTable>
				<DialogActions>
					<Button color="primary" onClick={props.onClose}>Zurück</Button>
				</DialogActions>
			</div>
		</Dialog >
	)
}

type EmployeeSelectProps = {
	currentEmployeeUUID: string
	activePeriod: {
		start: Date
		end: Date
	}
	onSubmit: EmployeeHandle
	keyboardEventInput?: {
		prev: string
		next: string
	}
}

export function EmployeeSelect(props: EmployeeSelectProps): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false)

	const employees = useGetEmployees(props.activePeriod)
	const activeEmployees = employees.data?.value
		?.filter(employee => employee.active)
	const currentEmployeeIndex = activeEmployees?.findIndex(employee => employee.uuid === props.currentEmployeeUUID) ?? -1
	
	const submitPrev = () => {
		const newIndex = (currentEmployeeIndex - 1 < 0)
			? (activeEmployees?.length ?? 0) - 1
			: currentEmployeeIndex - 1
		props.onSubmit(
			activeEmployees?.[newIndex]?.uuid ?? "",
			activeEmployees?.[newIndex]?.name.first,
			activeEmployees?.[newIndex]?.name.last
		)
	}
	const submitNext = () => {
		const newIndex = (currentEmployeeIndex === (activeEmployees?.length ?? -1) - 1)
			? 0
			: currentEmployeeIndex + 1
		props.onSubmit(
			activeEmployees?.[newIndex]?.uuid ?? "",
			activeEmployees?.[newIndex]?.name.first,
			activeEmployees?.[newIndex]?.name.last
		)
	}

	useRegisterKeyboardInput(
		props.keyboardEventInput?.prev ?? "",
		submitPrev
	)
	useRegisterKeyboardInput(
		props.keyboardEventInput?.next ?? "",
		submitNext
	)

	if (employees.isResult()) {
		if (employees.data.isError()) {
			return <Dialog open><LoadingError
				error={employees.data.error as Error}
				onReload={employees.refetchQuery}
			/></Dialog>
		}
	}

	return <>
		<EmployeeDialog
			open={dialogOpen}
			onClose={() => setDialogOpen(false)}
			onSubmit={props.onSubmit}
			onEnter={(uuid: string) => {
				const newIndex = activeEmployees?.findIndex(employee => employee.uuid === uuid) ?? -1
				props.onSubmit(
					activeEmployees?.[newIndex]?.uuid ?? "",
					activeEmployees?.[newIndex]?.name.first,
					activeEmployees?.[newIndex]?.name.last,
				)
			}}
			employees={employees.data.value ?? []}
		/>
		<LoadingDialog open={employees.isLoading()} />
		<ButtonGroup
			variant="contained"
			color="primary"
		>
			<Button
				onClick={submitPrev}
			><ArrowBack /></Button>
			<Button
				onClick={() => setDialogOpen(true)}
			>Angestellten auswählen</Button>
			<Button
				onClick={submitNext}
			><ArrowForward /></Button>
		</ButtonGroup>
	</>
}