import { makeStyles, TableHead, TableBody, TableCell, TableRow, DialogActions, Button } from "@material-ui/core";
import { red, yellow } from "@material-ui/core/colors";
import useTitle from "../TitleProvider";
import { useState } from "react";
import { addDays, isEqual, isValid } from "date-fns";
import { TableHeadCell, HoveredTableRow, StyledTable } from "../customUI";
import { MonthUtil } from "./MonthUtil";
import clsx from "clsx";
import { HolidayTooltip, tooltipHoliday } from "./HolidayTooltip";
import { SelectDialog } from "./SelectDialog/SelectDialog";
import { CreateDialog } from "./CreateDialog/CreateDialog";
import { CreatePublicDialog } from "./CreatePublicDialog/CreatePublicDialog";
import BigNumber from "bignumber.js";
import { format, KeyboardMonthPicker, PickerDate } from "../util/dateFnsWrapper";
import { useHistory, useParams } from "react-router-dom";
import { parse } from "date-fns/esm";
import { useGetEmployees } from "./getEmployees";
import { LoadingDialog } from "../customUI";
import { getHumanReadableHolidayDayAmounts } from "../gql/holidayUtil";
import { LoadingError } from "../Error/LoadingError";

const useStyle = makeStyles(theme => ({
	datePickerWrapper: {
		width: 'fit-content',
		margin: 'auto',
		marginBottom: theme.spacing(2),
	},
	headCell: {
		minWidth: '2ch',
		maxWidth: '2ch',
	},
	smallCell: {
		padding: theme.spacing(1),
		"&:last-child": {
			paddingRight: theme.spacing(1),
		},
	},
	selectableCell: {
		textAlign: 'center',
		borderLeftStyle: 'solid',
		borderLeftWidth: 1,
		borderLeftColor: 'rgba(224, 224, 224, 1)',
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
		},
	},
	weekendCell: {
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
	},
	yellowCell: {
		backgroundColor: yellow[400],
	},
	redCell: {
		backgroundColor: red[400],
	},
}))

type URLParams = {
	month?: string
}

export function Holidays() {
	const title = useTitle()
	const classes = useStyle()

	const { month: urlMonth } = useParams<URLParams>()
	const history = useHistory()

	let urlDate = new Date()
	const parsedDate = parse(urlMonth ?? "", "yyyy-MM", new Date())
	if (isValid(parsedDate)) {
		urlDate = parsedDate
	} else {
		history.push(`/holidays/${format(urlDate, "yyyy-MM")}`)
	}

	const updateURLDate = (newDate: PickerDate) => {
		if (newDate !== null) {
			if (!isEqual(newDate, urlDate)) {
				history.push(`/holidays/${format(newDate, "yyyy-MM")}`)
			}
		}
	}

	title?.setTitle(`Urlaube - ${format(urlDate, "LLLL yyyy")}`)

	const [selectedEmployee, setSelectedEmployee] = useState<number>(0)
	const [selectedDay, setSelectedDay] = useState<number>(0)

	const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
	const [createPublicDialogOpen, setCreatePublicDialogOpen] = useState<boolean>(false)
	const [selectDialogOpen, setSelectDialogOpen] = useState<boolean>(false)

	const monthUtil: MonthUtil = new MonthUtil(urlDate)

	const holidays = useGetEmployees(urlDate)
	if (holidays.data.isError()) {
		return (
			<LoadingError
				error={holidays.data.error as Error}
				onReload={holidays.refetchQuery}
			/>
		)
	}


	const filterZeros = (input: string): (string | undefined) => {
		return (input === '0') ? undefined : input
	}

	return (
		<>
			<LoadingDialog open={holidays.isLoading()} />
			<div className={classes.datePickerWrapper}>
				<KeyboardMonthPicker
					date={urlDate}
					setDate={d => updateURLDate(d)}
					keyboardEventInput={{
						prev: 'ArrowLeft',
						next: 'ArrowRight'
					}}
				/>
			</div>
			<StyledTable size="small">
				<TableHead>
					<HoveredTableRow>
						<TableHeadCell />
						{monthUtil.daysOfMonth.map(
							(day) =>
								<TableHeadCell
									align="center"
									className={clsx(classes.smallCell, classes.headCell)}
								>
									{(day + 1 + "").padStart(2, "0")}
									<br />
									{format(addDays(monthUtil.getStartOfMonthUTC(), day), "EEEEEE")}
								</TableHeadCell>
						)}
					</HoveredTableRow>
				</TableHead>
				<TableBody>
					{holidays.data.value?.map((employee, employeeIndex) => {
						const days = employee.holidays.map((day, index) => {
							let className = clsx(classes.smallCell, classes.selectableCell)
							if (monthUtil.isWeekend(index + 1)) {
								className = clsx(className, classes.weekendCell)
							}
							const totalContingent: BigNumber = (day.length === 0)
								? new BigNumber(0)
								: day.reduce<BigNumber>(
									(prev, curr) => prev.plus(curr.amountOfDay),
									new BigNumber(0),
								)
							if (totalContingent.isGreaterThan(1)) {
								className = clsx(className, classes.redCell)
							} else if (day.some(h => h.type === 'ILLNESS')) {
								className = clsx(className, classes.yellowCell)
							}
							const openDialog = (day.length === 0)
								? () => setCreateDialogOpen(true)
								: () => setSelectDialogOpen(true)
							return (
								<HolidayTooltip
									holidays={
										day.map(holiday => ({
											type: holiday.type,
											amount: holiday.amountOfDay,
											comment: holiday.comment,
										})) as tooltipHoliday[]
									}
								>
									<TableCell
										className={className}
										align="right"
										onClick={() => {
											setSelectedEmployee(employeeIndex)
											setSelectedDay(index)
											openDialog()
										}}
									>
										{filterZeros(
											getHumanReadableHolidayDayAmounts(
												totalContingent
											)
										)}
									</TableCell>
								</HolidayTooltip>
							)
						})
						return <TableRow>
							<TableCell className={classes.smallCell}>{employee.name}</TableCell>
							{days}
						</TableRow>
					})}
				</TableBody>
			</StyledTable>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					onClick={() => setCreatePublicDialogOpen(true)}
				>Gesetzlichen Feiertag anlegen</Button>
			</DialogActions>

			<CreatePublicDialog
				open={createPublicDialogOpen}
				onClose={() => setCreatePublicDialogOpen(false)}
				refetchDate={urlDate}
			/>
			<CreateDialog
				open={createDialogOpen}
				onClose={() => setCreateDialogOpen(false)}
				onBack={() => setSelectDialogOpen(true)}
				data={{
					date: monthUtil.getMonthDateByDay(selectedDay + 1),
					employeeID: holidays.data.value?.[selectedEmployee]?.uuid as string,
					employeeName: holidays.data.value?.[selectedEmployee]?.name as string,
				}}
			/>
			<SelectDialog
				open={selectDialogOpen}
				onClose={() => setSelectDialogOpen(false)}
				onSubmit={() => setCreateDialogOpen(true)}
				onEditBack={() => setSelectDialogOpen(true)}
				data={{
					employeeName: holidays.data.value?.[selectedEmployee]?.name ?? "",
					holidays: holidays.data.value?.[selectedEmployee]?.holidays[selectedDay] ?? [],
				}}
				refetchDate={urlDate}
			/>
		</>
	)
}