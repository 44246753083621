import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { Maybe } from "./maybe";

const logoutMutation = gql`
  mutation logout {
    backend_user_logout
  }
`

type Result = {
  backend_user_logout: boolean
}

export function useLogout(
  options?: MutationHookOptions<Result, null>
): () => Promise<Maybe<boolean>> {
  const [mutation] = useMutation<Result, null>(
    logoutMutation,
    options,
  )

  return async () => {
    try {
      const result = await mutation()
      if (result.data != null) {
        return Maybe.Just(result.data.backend_user_logout)
      }
      return Maybe.None()
    } catch (e: any) {
      return Maybe.Error(e)
    }
  }
}