import { Typography, Button, DialogTitle, makeStyles, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { StaffNumber, Caption, MailTo, NFCTag, Interval, Currency, getHourlyEarning } from "../customUI/formatting";
import { SpecialChars } from '../customUI'
import { format } from "../util/dateFnsWrapper";
import { ParsedEmployee } from "./getEmployees";
import * as Icons from '@material-ui/icons'
import clsx from "clsx";
import "../customCSS/scrollbar.css"
import { TaxID } from "../customUI/formatting/TaxID";

const useStyle = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  contentWrapper: {
    margin: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
  },
  wideCell: {
    gridColumn: 'span 2',
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    gridColumn: 'span 2',
  },
  contract: {
    overflow: 'hidden',
  },
  contractSummary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  contractSummaryContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    width: '100%',
  },
  targetMinutesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loanWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
  },
}))

type Props = {
  open: boolean
  onClose: () => void
  employee: ParsedEmployee
}

export function DetailsDialog(props: Props): JSX.Element {
  const classes = useStyle()

  const birthDate = (props.employee.birthDate !== undefined)
    ? format(props.employee.birthDate, "dd.MM.yyyy")
    : SpecialChars.emDash

  return (
    <Dialog
      fullWidth
      open={props.open}
      onBackdropClick={props.onClose}
    >
      <DialogTitle className={classes.header}>
        {`${props.employee.name.first} ${props.employee.name.last}`}
      </DialogTitle>
      <div className={clsx("scrollbar", "scrollbar-overlay")}>

        <div className={classes.contentWrapper}>
          <Typography className={classes.wideCell} variant="h6">
            Stammdaten
          </Typography>
          <Caption caption="Vorame">
            {props.employee.name.first}
          </Caption>
          <Caption caption="Nachname">
            {props.employee.name.last}
          </Caption>
          <Caption caption="Mailadresse">
            <MailTo mail={props.employee.mail} />
          </Caption>
          <Caption caption="NFC-Tag">
            <NFCTag tag={props.employee.nfcTag} />
          </Caption>
          <Caption caption="Mitarbeiternummer">
            <StaffNumber number={props.employee.staffNumber} />
          </Caption>
          <Caption caption="Geburtsdatum">
            {birthDate}
          </Caption>
          <Caption caption="Adresse">
            {
              props.employee.address
                ? `${props.employee.address.street}, ${props.employee.address.zipCode} ${props.employee.address.town}`
                : SpecialChars.emDash
            }
          </Caption>
          <Caption caption="Steuernummer">
            <TaxID id={props.employee.taxID} />
          </Caption>

          <Typography className={classes.sectionTitle} variant="h6">
            Arbeitsverträge
          </Typography>
          <div className={clsx(classes.wideCell)}>
            {props.employee.employmentPeriods.map(period => {
              return (
                <Accordion className={classes.contract}>
                  <AccordionSummary
                    className={classes.contractSummary}
                    expandIcon={<Icons.ExpandMore />}
                  >
                    <div className={classes.contractSummaryContent}>
                      <Typography>Beginn:</Typography>
                      <Typography>{format(period.start, "MM.yyyy")}</Typography>
                      <Typography>Ende:</Typography>
                      <Typography>{format(period.end, "MM.yyyy")}</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: '100%' }}>
                      <Typography variant="h6">
                        Arbeitszeiten
                      </Typography>
                      <Caption caption="Wochenstunden">
                        <Interval minutes={period.targetWeekMinutes} padLengthHours={2} />
                      </Caption>
                      <div className={classes.targetMinutesGrid}>
                        <Caption caption="Montag">
                          <Interval minutes={period.targetMinutes.monday ?? 0} padLengthHours={2} />
                        </Caption>
                        <Caption caption="Dienstag">
                          <Interval minutes={period.targetMinutes.tuesday ?? 0} padLengthHours={2} />
                        </Caption>
                        <Caption caption="Mittwoch">
                          <Interval minutes={period.targetMinutes.wednesday ?? 0} padLengthHours={2} />
                        </Caption>
                        <Caption caption="Donnerstag">
                          <Interval minutes={period.targetMinutes.thursday ?? 0} padLengthHours={2} />
                        </Caption>
                        <Caption caption="Freitag">
                          <Interval minutes={period.targetMinutes.friday ?? 0} padLengthHours={2} />
                        </Caption>
                        <Caption caption="Samstag">
                          <Interval minutes={period.targetMinutes.saturday ?? 0} padLengthHours={2} />
                        </Caption>
                        <Caption caption="Sonntag">
                          <Interval minutes={period.targetMinutes.sunday ?? 0} padLengthHours={2} />
                        </Caption>
                      </div>
                      <Caption caption="Urlaubstage im Jahr">
                        {period.dueHolidays} Tage
                      </Caption>
                      <Typography className={classes.sectionTitle} variant="h6">
                        Gehalt
                      </Typography>
                      <div className={classes.loanWrapper}>
                        <Caption caption="Gehalt pro Monat">
                          <Currency value={period.loan} />
                        </Caption>
                        <Caption caption="Gehalt pro Stunde">
                          <Currency value={getHourlyEarning(period.loan, period.targetWeekMinutes)} />
                        </Caption>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
          <Typography className={classes.sectionTitle} variant="h6">
            Technische Informationen
          </Typography>
          <Caption classNames={classes.wideCell} caption="UUID">
            {props.employee.uuid}
          </Caption>
        </div>
      </div>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onClose}
        >zurück</Button>
      </DialogActions>
    </Dialog>
  )
}