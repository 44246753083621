import { SpecialChars } from '../'

type Props = {
  number: number | undefined
}

export function StaffNumber(props: Props) {
  const renderedNumber = props.number
    ? (props.number + "").padStart(5, '0')
    : SpecialChars.emDash
  return <>{renderedNumber}</>
}