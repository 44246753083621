import { Caption, FormDialog, LoadingDialog } from "../../customUI";
import { useError } from "../../Error/ErrorProvider";
import { useState } from 'react'
import { useEditEvent } from "./editEvent";
import { ParsedWorkingPeriod } from "../getDetails";
import usePropsState from "../../states/usePropsState";
import { format, KeyboardDateTimePicker, PickerDate } from "../../util/dateFnsWrapper";
import { Typography, makeStyles } from "@material-ui/core"
import { isEqual } from "date-fns";

const useStyle = makeStyles(theme => ({
	section: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		gap: theme.spacing(2),
	}
}))

type Props = {
	open: boolean
	onClose: () => void
	onSubmit: () => void
	period: ParsedWorkingPeriod
	employeeUUID: string
	month: Date
}

export function EditEventDialog(props: Props) {
	const classes = useStyle()
	const errorHandler = useError()

	const [startDate, setStartDate] = usePropsState<PickerDate>(props.period?.start ?? null)
	const [endDate, setEndDate] = usePropsState<PickerDate>(props.period?.end ?? null)

	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
	const editEvent = useEditEvent()

	if (props.period == null) {
		return <></>
	}

	return <>
		<LoadingDialog open={loadingDialogOpen} />
		<FormDialog
			open={props.open}
			onClose={props.onClose}
			onBack={props.onClose}
			onSubmit={async () => {
				let errors: Error[] = []
				setLoadingDialogOpen(true)
				if (!isEqual(props.period.start, startDate as Date)) {
					const result = await editEvent({
						eventID: props.period.startEventUUID,
						newDate: startDate as Date,
						employeeUUID: props.employeeUUID,
						month: props.month
					})
					if (result.isError()) {
						errors.push(result.error as Error)
					}
				}
				if (!isEqual(props.period.end, endDate as Date)) {
					const result = await editEvent({
						eventID: props.period.endEventUUID,
						newDate: endDate as Date,
						employeeUUID: props.employeeUUID,
						month: props.month
					})
					if (result.isError()) {
						errors.push(result.error as Error)
					}
				}
				setLoadingDialogOpen(false)
				errors.forEach(e => errorHandler.pushError(e))
			}}
			data={{
				title: "Arbeitsinterval bearbeiten",
				submitButtonText: "bearbeiten"
			}}
			style={{ gridTemplateColumns: 'auto' }}
		>
			<div>
				<Typography variant="h6">
					Startzeit
				</Typography>
				<div className={classes.section}>
					<Caption
						caption="Initial"
						underline
					>{format(props.period?.start, "HH:mm dd.MM.yyy")}</Caption>
					<KeyboardDateTimePicker
						control={{
							state: startDate,
							setState: setStartDate,
						}}
						label="Neu"
					/>
				</div>
			</div>
			<div>
				<Typography variant="h6">
					Endzeit
				</Typography>
				<div className={classes.section}>
					<Caption
						caption="Initial"
						underline
					>{format(props.period?.end, "HH:mm dd.MM.yyyy")}</Caption>
					<KeyboardDateTimePicker
						control={{
							state: endDate,
							setState: setEndDate,
						}}
						label="Neu"
					/>
				</div>
			</div>
		</FormDialog>
	</>
}