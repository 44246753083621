import { Box, Dialog, CircularProgress } from "@material-ui/core"

type Props = {
  open: boolean
}

export function LoadingDialog(props: Props): JSX.Element {
  return <Dialog open={props.open}>
    <Box p={2}>
      <CircularProgress />
    </Box>
  </Dialog>
}