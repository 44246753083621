import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { Maybe } from "../maybe";

const loginMutation = gql`
  mutation login(
    $mail: String!,
    $password: String!
  ) {
    backend_user_login(
      user_mail: $mail,
      password: $password
    ) {
      id
    }
  }
`

type Params = {
  mail: string
  password: string
}

type Result = {
  backend_user_login: {
    id: string
  }
}

export function useLogin(
  options?: MutationHookOptions<Result, Params>
): (data: Params) => Promise<Maybe<boolean>> {
  const [mutation] = useMutation<Result, Params>(
    loginMutation,
    options,
  )

  return async (data: Params) => {
    try {
      const mutationResult = await mutation({
        variables: data,
      })
      if (mutationResult.data != null) {
        return Maybe.Just(true)
      }
      return Maybe.None()
    } catch(e: any) {
      return Maybe.Error(e)
    }
  }
}