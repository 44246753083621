import { createStyles, TableCell, TableRow, withStyles } from "@material-ui/core";

export const TableHeadCell = withStyles(theme =>
	createStyles({
		head: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		body: {
			fontSize: 14,
		},
	}),
)(TableCell)

export const HoveredTableRow = withStyles(theme =>
createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}),
)(TableRow)