import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { makeStyles } from "@material-ui/core"
import BigNumber from "bignumber.js";
import { SanatizedTargetMinutesGQL } from "./targetMinutes";

export const employmentPeriodStyle = makeStyles(theme => ({
  numberInput: {
    textAlign: 'right',
  },
  timeGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.spacing(2),
  },
  halfWidth: {
    width: '50%',
  },
}))

export const loanMask = (props: any) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        prefix: '',
        suffix: ' €',
        thousandsSeparatorSymbol: '.',
        decimalSymbol: ',',
        requireDecimal: true,
      })}
      showMask
    />
  )
}

export const dayMask = (props: any) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={createNumberMask({
        prefix: '',
        suffix: ' Tage',
        thousandsSeparatorSymbol: '.',
      })}
      showMask
    />
  )
}

export type EmploymentPeriod = {
  id: number
  start: Date
  end: Date
  targetWeekMinutes: number
  targetMinutes: SanatizedTargetMinutesGQL
  dueHolidays: number
  loan: BigNumber
}