import { List, ListItemSecondaryAction } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import { Interval } from "../customUI"
import CollapsiblePanel from "../customUI/CollapsiblePanel"

type Props = {
  collapsed?: boolean
  overtime: BigNumber
  holidays: {
    total: number
    left: number
    taken: number
  }
}

export default function Summary(props: Props): JSX.Element {
  return (
    <CollapsiblePanel collapsed={props.collapsed} title="Übersicht">
      <Typography variant="h6">Überstunden</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Aktuelle Überstunden:" />
          <ListItemSecondaryAction>
            <Interval minutes={props.overtime} padLengthHours={0} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Typography variant="h6">Urlaub</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Gesamturlaubstage:" />
          <ListItemSecondaryAction>
            {props.holidays.total}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="Urlaubstage verbleibend:" />
          <ListItemSecondaryAction>
            {props.holidays.left}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="Urlaubstage genommen:" />
          <ListItemSecondaryAction>
            {props.holidays.taken}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </CollapsiblePanel>
  )
}