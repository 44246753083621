import { gql, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { add, areIntervalsOverlapping, endOfDay, endOfMonth, getDay, getDaysInMonth, isSameDay, startOfDay, startOfMonth } from "date-fns";
import { getHourlyEarning } from "../customUI";
import { getHumanReadableHoliday, HolidayType, PayoutType, QueryResult } from "../gql";

const getPrintInformation = gql`
  query getPrintInformation(
    $employeeID: ID!
    $start: DateTime!,
    $end: DateTime!
  ) {
    employee_by_id(
      employee_id: $employeeID
    ) {
      first_name,
      last_name,
      holidays(
        period_start: $start,
        period_end: $end
      ) {
        type,
        amount_of_day,
        date
      },
      employment_periods {
        period_start,
        period_end,
        target_minutes {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday
        },
        target_week_minutes,
        loan,
        due_holidays_per_year
      },
      working_periods(
        period_start: $start,
        period_end: $end
      ) {
        start,
        end,
        duration_in_minutes
      },
      payouts(
        period_start: $start,
        period_end: $end
      ) {
        type,
        effective_date,
        value_in_hours
      },
      overtime(
        period_end: $end
      )
    }
  }
`

type Params = {
  employeeID: string
  start: Date
  end: Date
}

type Result = {
  employee_by_id: {
    first_name: string
    last_name: string
    holidays: {
      type: HolidayType
      amount_of_day: string
      date: string
    }[]
    employment_periods: {
      period_start: string
      period_end: string
      target_minutes: {
        monday: number
        tuesday: number
        wednesday: number
        thursday: number
        friday: number
        saturday: number
        sunday: number
      }
      target_week_minutes: number
      loan: string
      due_holidays: number
    }[]
    working_periods: {
      start: string
      end: string
      duration_in_minutes: string
    }[]
    payouts: {
      type: PayoutType
      effective_date: string
      value_in_hours: number
    }[]
    overtime: string
  }
}

type WorkingInterval = { start: Date, end: Date } 

type WorkingDay = {
  day: Date
  dueInMinutes: BigNumber
  actualInMinutes: BigNumber
  intervals: (WorkingInterval | string)[]
}

export type PrintInformation = {
  employee: {
    name: {
      first: string
      last: string
    }
  }
  holidays: {
    amountOfDay: BigNumber
    date: Date
  }[]
  illness: {
    amountOfDay: BigNumber
    date: Date
  }[]
  workingHours: WorkingDay[]
  payouts: {
    date: Date
    type: PayoutType
    valueInHours: number
    valueInEuro: BigNumber
  }[]
  overtime: BigNumber
}

function transformData(data: Result, fetchedMonth: Date): PrintInformation {
  const employee = data.employee_by_id
  const workingHours = []
  for (let i = 0; i < getDaysInMonth(fetchedMonth); i++) {
    const currentDay = add(startOfMonth(fetchedMonth), { days: i })
    const currentEmploymentPeriod = employee.employment_periods.find(
      period => areIntervalsOverlapping(
        { start: new Date(period.period_start), end: new Date(period.period_end) },
        { start: startOfDay(currentDay), end: endOfDay(currentDay) }
      )
    )
    const targetMinutes = currentEmploymentPeriod?.target_minutes
    let workingDay: WorkingDay = {
      day: currentDay,
      dueInMinutes: new BigNumber(0),
      actualInMinutes: new BigNumber(0),
      intervals: [],
    }
    switch (getDay(currentDay)) {
      case 0: workingDay.dueInMinutes = new BigNumber(targetMinutes?.sunday ?? 0); break
      case 1: workingDay.dueInMinutes = new BigNumber(targetMinutes?.monday ?? 0); break
      case 2: workingDay.dueInMinutes = new BigNumber(targetMinutes?.tuesday ?? 0); break
      case 3: workingDay.dueInMinutes = new BigNumber(targetMinutes?.wednesday ?? 0); break
      case 4: workingDay.dueInMinutes = new BigNumber(targetMinutes?.thursday ?? 0); break
      case 5: workingDay.dueInMinutes = new BigNumber(targetMinutes?.friday ?? 0); break
      case 6: workingDay.dueInMinutes = new BigNumber(targetMinutes?.saturday ?? 0); break
    }
    workingDay.actualInMinutes = employee.working_periods
      .filter(period => isSameDay(currentDay, new Date(period.start)))
      .reduce(
        (prev, curr) => {
          workingDay.intervals.push({ start: new Date(curr.start), end: new Date(curr.end) })
          return prev.plus(curr.duration_in_minutes)
        },
        new BigNumber(0),
      )
    const holidays = employee.holidays.filter(
      h => isSameDay(new Date(h.date), currentDay)
    )
    holidays.forEach(h => {
      workingDay.actualInMinutes = workingDay.actualInMinutes.plus(
        workingDay.dueInMinutes.multipliedBy(h.amount_of_day)
      )
      workingDay.intervals.push(getHumanReadableHoliday(h.type))
    })
    if (
      workingDay.dueInMinutes.isGreaterThan(0) ||
      workingDay.actualInMinutes.isGreaterThan(0)
    ) {
      workingHours.push(workingDay)
    }
  }
  return {
    employee: {
      name: {
        first: employee.first_name,
        last: employee.last_name,
      }
    },
    holidays: employee.holidays
      .filter(h => h.type === 'EMPLOYEE_HOLIDAY')
      .map(h => ({
        amountOfDay: new BigNumber(h.amount_of_day),
        date: new Date(h.date)
      })),
    illness: employee.holidays
      .filter(h => h.type === 'ILLNESS')
      .map(h => ({
        amountOfDay: new BigNumber(h.amount_of_day),
        date: new Date(h.date)
      })),
    workingHours,
    payouts: employee.payouts
      .map(payout => {
        const effectiveDate = new Date(payout.effective_date)
        const targetMinutes = employee.employment_periods.find(
          period => areIntervalsOverlapping(
            { start: new Date(period.period_start), end: new Date(period.period_end) },
            { start: startOfDay(effectiveDate), end: endOfDay(effectiveDate) }
          )
        )?.target_week_minutes
        return ({
          date: effectiveDate,
          type: payout.type,
          valueInHours: payout.value_in_hours,
          valueInEuro: getHourlyEarning(payout.value_in_hours, targetMinutes ?? 0)
        })
      }),
    overtime: new BigNumber(employee.overtime)
  }
}

type PrintParams = {
  employeeID: string
  month: Date
}

export function useGetPrintInformation(params: PrintParams): QueryResult<PrintInformation, Result, Params> {
  const queryResult = useQuery<Result, Params>(
    getPrintInformation,
    {
      variables: {
        employeeID: params.employeeID,
        start: startOfMonth(params.month),
        end: endOfMonth(params.month)
      },
    }
  )
  return new QueryResult(
    queryResult,
    toTransform => transformData(toTransform, params.month),
    {
      refetchVariables: {
        employeeID: params.employeeID,
        start: startOfMonth(params.month),
        end: endOfMonth(params.month)
      },
    }
  )
}