import BigNumber from "bignumber.js";

type Props = {
  minutes: BigNumber.Value
  padLengthHours?: number
}

export function Interval(props: Props): JSX.Element {
  const converted = new BigNumber(props.minutes)
  const interval = converted.abs().integerValue()

  let durationHours: BigNumber = interval.dividedToIntegerBy(60)
  let durationMinutes: BigNumber = interval.minus(durationHours.multipliedBy(60))

  const prefix = converted.isNegative() ? '-' : ''
  const hours: string = durationHours.toFixed(0).padStart(props.padLengthHours ?? 3, "0")
  const minutes: string = durationMinutes.toFixed(0).padStart(2, "0")

  return <>{`${prefix}${hours}:${minutes}`}</>
}