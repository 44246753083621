import BigNumber from "bignumber.js"

type CurrencyProps = {
  value: BigNumber.Value
  currencySuffix?: string
}

export function Currency(props: CurrencyProps): JSX.Element {
  const bigNumberFormat: BigNumber.Format = {
    decimalSeparator: ',',
    groupSeparator: '.',
    suffix: props.currencySuffix ?? ' €',
  }
  BigNumber.config({ FORMAT: bigNumberFormat })
  return <>{new BigNumber(props.value).toFormat(2)}</>
}

const weeksPerMonth = new BigNumber(365).dividedBy(7 * 12)

export function getHourlyEarning(
  loanPerMonth: BigNumber.Value,
  targetMinutesPerWeek: number
): BigNumber {
  const targetMinutesPerMonth = weeksPerMonth.times(targetMinutesPerWeek)
  const targetHoursPerMonth = targetMinutesPerMonth.dividedBy(60)
  return new BigNumber(loanPerMonth).dividedBy(targetHoursPerMonth)
}