import BigNumber from "bignumber.js"
import { HolidayType } from "./enums"

type selectArrayEntry = {
	value: string
	label: string
}

export const holidaySelect: selectArrayEntry[] = [
	{
		value: "EMPLOYEE_HOLIDAY",
		label: "Urlaub",
	},
	{
		value: "PUBLIC_HOLIDAY",
		label: "Öffentlicher Feiertag",
	},
	{
		value: "ILLNESS",
		label: "Krankheit",
	},
	{
		value: "COMPENSATORY_TIME_OFF",
		label: "Überstunden",
	}
]

export function getHumanReadableHoliday(h: HolidayType): string {
	switch (h) {
		case "COMPENSATORY_TIME_OFF":
			return "Überstunden"
		case "EMPLOYEE_HOLIDAY":
			return "Urlaub"
		case "ILLNESS":
			return "Krankheit"
		case "PUBLIC_HOLIDAY":
			return "Öffentlicher Feiertag"
		default:
			return ""
	}
}

export const holidayDayAmounts: [BigNumber, BigNumber, BigNumber, BigNumber] = [
	new BigNumber(0.25), 
	new BigNumber(0.5),
	new BigNumber(0.75), 
	new BigNumber(1),
]

export function getHumanReadableHolidayDayAmounts(val: BigNumber): string {
	if (val.isEqualTo(0))
		return '0'
	if (val.isEqualTo(holidayDayAmounts[0])) 
		return '¼' 
	if (val.isEqualTo(holidayDayAmounts[1]))
		return '½'
	if (val.isEqualTo(holidayDayAmounts[2]))
		return '¾'
	if (val.isEqualTo(1)) {
		return '1'
	}
	return '—'
}