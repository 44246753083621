import { gql, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { endOfMonth, endOfYear, getDate, startOfMonth, startOfYear } from "date-fns";
import { HolidayType, QueryResult } from "../gql";

const getDetailsQuery = gql`
  query getDetailsTimesView(
    $id: ID!,
    $startOfMonth: DateTime!,
    $endOfMonth: DateTime!,
    $startOfYear: DateTime!,
    $endOfYear: DateTime!
  ) {
    employee_by_id(
      employee_id: $id,
    ) {
      holidays(
        period_start: $startOfMonth,
        period_end: $endOfMonth
      ) {
        type,
        date
      },
      overtime,
      overtime_of_month: overtime(
        period_end: $endOfMonth
      ),
      payouts(
        period_start: $startOfMonth,
        period_end: $endOfMonth,
      ) {
        type,
        value_in_hours
      },
      due_holidays(
        period_start: $startOfYear,
        period_end: $endOfYear
      )
    }
    working_period_by_employee_by_period(
      employee_id: $id,
      start: $startOfMonth,
      end: $endOfMonth
    ) {
      employee_id,
      start,
      start_event_id,
      end,
      end_event_id,
      duration_in_minutes
    }
  }
`

export const refetchGetDetailsTimesViewQuery =
  (employeeUUID: string, month: Date) => ({
    query: getDetailsQuery,
    variables: {
      id: employeeUUID,
      startOfMonth: startOfMonth(month),
      endOfMonth: endOfMonth(month),
      startOfYear: startOfYear(month),
      endOfYear: endOfYear(month)
    }
  })

type Params = {
  id?: string
  startOfMonth: Date
  endOfMonth: Date
  startOfYear: Date
  endOfYear: Date
}

type FetchedHoliday = {
  type: HolidayType
  date: string
}

type Result = {
  employee_by_id: {
    holidays: FetchedHoliday[]
    overtime: string
    overtime_of_month: string
    payouts: {
      type: string
      value_in_hours: number
    }[],
    due_holidays: number
  }
  working_period_by_employee_by_period: {
    employee_id: string
    start: string
    start_event_id: string
    end: string
    end_event_id: string
    duration_in_minutes: string
  }[]
}

type HookParams = {
  employeeUUID?: string
  month: Date
}

type Interval = {
  start: Date
  end: Date
}

export type ParsedWorkingPeriod = {
  start: Date
  startEventUUID: string
  end: Date
  endEventUUID: string
  durationInMinutes: BigNumber
}

type Details = {
  overtime: BigNumber
  overtimeOfMonth: BigNumber
  payouts: BigNumber
  holidays: {
    total: number
    taken: number
    intervals: Interval[]
  }
  daysOfIllness: number
  workingPeriods: ParsedWorkingPeriod[][]
}

function transformData(data: Result): Details {
  const parseIntervals = (intervals: FetchedHoliday[]): Interval[] => {
    const parsedIntervals = intervals
      .filter(interval => interval.type === "EMPLOYEE_HOLIDAY")
      .map(value => new Date(value.date))
    const result: Interval[] = []
    let start: Date | undefined = parsedIntervals[0]
    let currentLast = parsedIntervals[0]
    for (let i = 1; i < parsedIntervals.length; i++) {
      if (getDate(currentLast) + 1 === getDate(parsedIntervals[i])) {
        currentLast = parsedIntervals[i]
      } else {
        result.push({ start, end: currentLast })
        start = parsedIntervals[i]
        currentLast = parsedIntervals[i]
      }
    }
    if (start !== undefined) {
      result.push({ start, end: currentLast })
    }
    return result
  }

  const workingPeriods: ParsedWorkingPeriod[][] = []
  for (const period of data.working_period_by_employee_by_period ?? []) {
    const date = getDate(new Date(period.start))
    const parsedPeriod: ParsedWorkingPeriod = {
      start: new Date(period.start),
      startEventUUID: period.start_event_id,
      end: new Date(period.end),
      endEventUUID: period.end_event_id,
      durationInMinutes: new BigNumber(period.duration_in_minutes),
    }
    if (workingPeriods[date] === undefined) {
      workingPeriods[date] = []
    }
    workingPeriods[date].push(parsedPeriod)
  }

  const summedPayouts = data.employee_by_id.payouts
    .filter(payout => payout.type === "OVERTIME_COMPENSATION")
    .reduce((prev, curr) => prev + curr.value_in_hours, 0)
  const holidaysTaken = data.employee_by_id.holidays
    .filter(holiday => holiday.type === "EMPLOYEE_HOLIDAY")
    .length
  const daysOfIllness = data.employee_by_id.holidays
    .filter(holiday => holiday.type === "ILLNESS")
    .length

  return {
    overtime: new BigNumber(data.employee_by_id.overtime),
    overtimeOfMonth: new BigNumber(data.employee_by_id.overtime_of_month),
    payouts: new BigNumber(summedPayouts * 60),
    holidays: {
      total: data.employee_by_id.due_holidays,
      taken: holidaysTaken,
      intervals: parseIntervals(data.employee_by_id.holidays ?? []),
    },
    daysOfIllness,
    workingPeriods,
  }
}

export function useGetDetails(data: HookParams): QueryResult<Details, Result, Params> {
  const queryResult = useQuery<Result, Params>(
    getDetailsQuery,
    {
      variables: {
        id: data.employeeUUID,
        startOfMonth: startOfMonth(data.month),
        endOfMonth: endOfMonth(data.month),
        startOfYear: startOfYear(data.month),
        endOfYear: endOfYear(data.month)
      },
      skip: data.employeeUUID === undefined,
    }
  )
  return new QueryResult(
    queryResult,
    transformData,
    {
      refetchVariables: {
        id: data.employeeUUID,
        startOfMonth: startOfMonth(data.month),
        endOfMonth: endOfMonth(data.month),
        startOfYear: startOfYear(data.month),
        endOfYear: endOfYear(data.month)
      },
      skipCondition: data.employeeUUID === undefined,
    },
  )
}