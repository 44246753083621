import { gql, useQuery } from "@apollo/client";
import { QueryResult } from "../gql";

const getEmployeeQuery = gql`
	query getEmployeeTimesView(
		$id: ID!
	) {
		employee_by_id(
			employee_id: $id
		) {
			id,
			first_name,
			last_name,
			additional_info {
				staff_number
			}
		}
	}
`

type Params = {
	id?: string
}

type Result = {
	employee_by_id: {
		id: string
		first_name: string
		last_name: string
		additional_info: {
			staff_number: number | null
		}
	}
}

export type Employee = {
	uuid: string
	name: string
	staffNumber?: number
}

function transformData(data: Result): Employee {
	const employee = data.employee_by_id
	return {
		uuid: employee.id,
		name: `${employee.first_name} ${employee.last_name}`,
		staffNumber: (employee.additional_info.staff_number !== null)
			? employee.additional_info.staff_number
			: undefined,
	}
}

export function useGetEmployee(data: Params): QueryResult<Employee, Result, Params> {
	const queryResult = useQuery<Result, Params>(
		getEmployeeQuery,
		{
			variables: data,
			skip: data.id === undefined,
		},
	)
	return new QueryResult(
		queryResult,
		transformData,
		{
			refetchVariables: data,
			skipCondition: data.id === undefined,
		}
	)
}