import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import { useState } from "react"
import { LoadingDialog, FormDialog } from "../../customUI"
import { useError } from "../../Error/ErrorProvider"
import { getHumanReadableHolidayDayAmounts, holidayDayAmounts, holidaySelect, HolidayType } from "../../gql"
import usePropsState from "../../states/usePropsState"
import { KeyboardDatePicker, PickerDate } from "../../util/dateFnsWrapper"
import { useCreateHoliday } from "./createHoliday"

const useStyle = makeStyles(theme => ({
	employeeField: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	wideField: {
		gridColumnStart: 1,
		gridColumnEnd: 3,
	},
}))

type createData = {
	date: Date
	employeeID: string
	employeeName: string
}

export type Props = {
	open: boolean
	onClose: () => void
	onBack: () => void
	data: createData
}

export function CreateDialog(props: Props) {
	const classes = useStyle()
	const errorHandler = useError()

	const [holidayType, setHolidayType] = useState<HolidayType>("EMPLOYEE_HOLIDAY")
	const [holidayAmount, setHolidayAmount] = useState<BigNumber>(new BigNumber(1))
	const [holidayComment, setHolidayComment] = useState<string | undefined>(undefined)
	const [holidayDate, setHolidayDate] = usePropsState<PickerDate>(props.data.date)

	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
	const createHoliday = useCreateHoliday(props.data.date)

	return (
		<>
			<LoadingDialog open={loadingDialogOpen} />
			<FormDialog
				open={props.open}
				onClose={props.onClose}
				onBack={props.onBack}
				onSubmit={async () => {
					setLoadingDialogOpen(true)
					const result = await createHoliday({
						employeeID: props.data.employeeID,
						type: holidayType,
						amount: holidayAmount,
						date: holidayDate as Date,
						comment: holidayComment,
					})
					setLoadingDialogOpen(false)
					if (result.isError()) {
						errorHandler.pushError(result.error as Error)
					}
					setHolidayType("EMPLOYEE_HOLIDAY")
					setHolidayAmount(new BigNumber(1))
					setHolidayComment(undefined)
				}}
				data={{
					title: "Urlaub erstellen",
					submitButtonText: "Urlaub erstellen",
				}}
			>
				<div
					className={classes.wideField}
				>
					<Typography variant="caption" color="textSecondary">Angestellter</Typography>
					<Typography
						variant="h6"
					>{props.data.employeeName}</Typography>
				</div>
				<FormControl>
					<InputLabel id="holiday-type-select-label">Urlaubsart</InputLabel>
					<Select
						id="holiday-type-select"
						label="Urlaubsart"
						value={holidayType}
						onChange={(event) => setHolidayType(event.target.value as HolidayType)}
					>
						{holidaySelect.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="holiday-amount-select-label">Kontingent</InputLabel>
					<Select
						id="holiday-amount-select"
						label="Kontingent"
						value={holidayAmount}
						onChange={event => setHolidayAmount(new BigNumber(event.target.value as string))}
					>
						{holidayDayAmounts.map((entry) => (
							<MenuItem key={entry.toFixed()} value={entry.toFixed()}>
								{getHumanReadableHolidayDayAmounts(entry)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<KeyboardDatePicker
					control={{
						state: holidayDate,
						setState: setHolidayDate,
					}}
					className={classes.wideField}
					views={["date", "month", "year"]}
					label="Datum auswählen"
				/>
				<TextField
					className={classes.wideField}
					label="Kommentar"
					multiline
					rows={4}
					rowsMax={10}
					value={holidayComment}
					onChange={event => setHolidayComment(event.target.value)}
				></TextField>
			</FormDialog>
		</>
	)
}