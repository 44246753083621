import { useEffect, useState } from "react";

function usePersistedState(key: string, defaultValue: any) {
	const [state, setState] = useState(
		() => {
			try {
				return JSON.parse(localStorage.getItem(key) ?? "null") ?? defaultValue
			} catch (e) {
				return defaultValue
			}
		}
	);
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
}

export default usePersistedState