import * as SpecialChars from '../specialChars'

type Props = {
  tag: string | undefined
}

export function NFCTag(props: Props): JSX.Element {
  const formattedTag = (props.tag != null)
    ? props.tag.replace(/(.{4})/g, '$1 ')
    : SpecialChars.emDash
  return <>{formattedTag}</>
}