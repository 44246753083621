import { gql, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { endOfMonth, getDate, getDaysInMonth, startOfMonth } from "date-fns";
import { QueryResult, HolidayType } from "../gql";

const getEmployeesQuery = gql`
	query getEmployeesQuery(
		$start: DateTime!,
		$end: DateTime!
	) {
		employee_get_active(
			employment_period_start: $start,
			employment_period_end: $end
		) {
			id,
			first_name,
			last_name,
      additional_info {
        staff_number
      },
			holidays(
				period_start: $start,
				period_end: $end
			) {
				id,
				type,
				amount_of_day,
				date,
				comment
			},
		}
	}
`

export const refetchGetEmployeesQuery =
  (date: Date) => ({
    query: getEmployeesQuery,
    variables: {
      start: startOfMonth(date),
      end: endOfMonth(date),
    },
  })

export type FetchInterval = {
  start: Date
  end: Date
}

type Result = {
  employee_get_active: {
    id: string
    first_name: string
    last_name: string
    additional_info: {
      staff_number?: number
    }
    holidays: {
      id: string
      type: string
      amount_of_day: string
      date: string
      comment: string
    }[]
  }[]
}

type Holiday = {
  id: number
  type: HolidayType
  amountOfDay: BigNumber
  date: Date
  comment?: string
}

type ParsedResult = {
  uuid: string
  name: string
  holidays: Holiday[][]
}

function transformData(data: Result, fetchedMonth: Date): ParsedResult[] {
  const result: ParsedResult[] = []
  //data.employee_get_active.sort(
  //  (lhs, rhs) => (rhs.additional_info.staff_number ?? 0) - (lhs.additional_info.staff_number ?? 0)
  //)
  for (const employee of data.employee_get_active ?? []) {
    const entry: ParsedResult = {
      uuid: employee.id,
      name: `${employee.first_name} ${employee.last_name}`,
      holidays: Array.from(Array(getDaysInMonth(fetchedMonth))).map(_ => []),
    }

    for (const holiday of employee.holidays) {
      const date = getDate(new Date(holiday.date)) - 1
      entry.holidays[date].push({
        id: parseInt(holiday.id),
        type: holiday.type as HolidayType,
        date: new Date(holiday.date),
        amountOfDay: new BigNumber(holiday.amount_of_day),
        comment: holiday.comment,
      })
    }
    result.push(entry)
  }
  return result
}

export function useGetEmployees(fetchedMonth: Date): QueryResult<ParsedResult[], Result, FetchInterval> {
  const queryResult = useQuery<Result, FetchInterval>(
    getEmployeesQuery,
    {
      variables: {
        start: startOfMonth(fetchedMonth),
        end: endOfMonth(fetchedMonth),
      },
    }
  )
  return new QueryResult(
    queryResult,
    (data: Result) => transformData(data, fetchedMonth),
    {
      refetchVariables: {
        start: startOfMonth(fetchedMonth),
        end: endOfMonth(fetchedMonth)
      }
    }
  )
}