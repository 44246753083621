import { gql, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { compareAsc, endOfDay, isEqual, max, minTime, startOfDay } from "date-fns";
import { QueryResult } from "../gql";

const getEmployeesQuery = gql`
	query getAllEmployees(
		$start: DateTime!,
		$end: DateTime!
	) {
		employee_get_active(
			employment_period_start: $start,
			employment_period_end: $end
		) {
			id,
			first_name,
			last_name,
			mail,
			key,
			additional_info {
				staff_number,
				birth_date,
        tax_id,
        address {
          street,
          zip_code,
          town
        }
			},
			employment_periods {
				id,
				period_start,
				period_end,
				target_week_minutes,
				target_minutes {
					monday,
					tuesday,
					wednesday,
					thursday,
					friday,
					saturday,
					sunday
				},
        due_holidays_per_year,
				loan
			}
		}
		employee_get_inactive(
			employment_period_start: $start,
			employment_period_end: $end
		) {
			id,
			first_name,
			last_name,
			mail,
			key,
			additional_info {
				staff_number,
				birth_date,
        tax_id,
        address {
          street,
          zip_code,
          town
        }
			},
			employment_periods {
				id,
				period_start,
				period_end,
				target_week_minutes,
				target_minutes {
					monday,
					tuesday,
					wednesday,
					thursday,
					friday,
					saturday,
					sunday
				},
        due_holidays_per_year,
				loan
			}
		}
	}
`

export const refetchGetEmployeesQuery = {
  query: getEmployeesQuery,
  variables: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
}

type Params = {
  start: Date
  end: Date
}

type Employee = {
  id: string
  first_name: string
  last_name: string
  mail: string
  key: string
  additional_info: {
    staff_number?: number
    birth_date?: string
    tax_id?: string
    address?: {
      street: string
      zip_code: string
      town: string
    }
  }
  employment_periods: {
    id: string
    period_start: string
    period_end: string
    target_week_minutes: number
    target_minutes: {
      monday?: number
      tuesday?: number
      wednesday?: number
      thursday?: number
      friday?: number
      saturday?: number
      sunday?: number
    }
    due_holidays_per_year: number
    loan: string
  }[]
}

type Result = {
  employee_get_active: Employee[]
  employee_get_inactive: Employee[]
}

export type ParsedEmployee = {
  isActive: boolean
  uuid: string
  name: {
    first: string
    last: string
  }
  mail: string
  nfcTag: string | undefined
  staffNumber: number | undefined
  birthDate: Date | undefined
  taxID: string | undefined
  address: {
    street: string
    zipCode: string
    town: string
  } | undefined
  employmentPeriods: {
    id: number
    start: Date
    end: Date
    targetWeekMinutes: number
    targetMinutes: {
      monday?: number
      tuesday?: number
      wednesday?: number
      thursday?: number
      friday?: number
      saturday?: number
      sunday?: number
    }
    dueHolidays: number
    loan: BigNumber
  }[]
}

type ParsedResult = ParsedEmployee[]

function transformData(data: Result): ParsedResult {
  const result: ParsedResult = []
  const minDate = new Date(minTime)

  const leavingDate = (employee: ParsedEmployee): Date | undefined => {
    let leavingDate: Date | undefined = employee.employmentPeriods.reduce(
      (maximum, curr) => max([maximum, new Date(curr.end)]),
      minDate,
    )
    if (isEqual(leavingDate, minDate)) {
      leavingDate = undefined
    }
    return leavingDate
  }

  const pushEmployee = (employee: Employee, isActive: boolean) => {
    result.push({
      isActive,
      uuid: employee.id,
      name: {
        first: employee.first_name,
        last: employee.last_name,
      },
      mail: employee.mail,
      nfcTag: employee.key,
      staffNumber: employee.additional_info.staff_number,
      birthDate: employee.additional_info.birth_date
        ? new Date(employee.additional_info.birth_date)
        : undefined,
      taxID: employee.additional_info.tax_id,
      address: employee.additional_info.address
        ? {
          street: employee.additional_info.address.street,
          zipCode: employee.additional_info.address.zip_code,
          town: employee.additional_info.address.town,
        }
        : undefined,
      employmentPeriods: employee.employment_periods.map(period => ({
        id: parseInt(period.id),
        start: new Date(period.period_start),
        end: new Date(period.period_end),
        targetWeekMinutes: period.target_week_minutes,
        targetMinutes: period.target_minutes,
        dueHolidays: period.due_holidays_per_year,
        loan: new BigNumber(period.loan),
      }))
    })
  }

  for (const employee of data.employee_get_active ?? []) {
    pushEmployee(employee, true)
  }
  for (const employee of data.employee_get_inactive ?? []) {
    pushEmployee(employee, false)
  }

  result.sort((lhs: ParsedEmployee, rhs: ParsedEmployee) => {
    const activeComp = (lhs.isActive === rhs.isActive) ? 0 : (lhs.isActive ? -1 : 1)
    const dateComp = compareAsc(leavingDate(lhs) ?? minDate, leavingDate(rhs) ?? minDate)
    const staffComp = (lhs.staffNumber ?? 0) - (rhs.staffNumber ?? 0)
    return (activeComp !== 0) ? activeComp : ((dateComp === 0) ? staffComp : dateComp)
  })
  return result
}

export function useGetEmployees(): QueryResult<ParsedResult, Result, Params> {
  const queryResult = useQuery<Result, Params>(
    getEmployeesQuery,
    {
      variables: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      }
    }
  )
  return new QueryResult(
    queryResult,
    transformData,
    {
      refetchVariables: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      },
    },
  )
}