import { TableRow, Collapse, Box, Typography, Table, IconButton, makeStyles, TableCell, TableBody, TableHead } from "@material-ui/core"
import clsx from "clsx"
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons"
import { useState } from "react"

const useStyle = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  box: {
    margin: theme.spacing(1)
  },
  detailsRow: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  boldCell: {
    fontWeight: 'bold'
  },
}))

export type TableRowKey = string | number

export type CellValue = {
  className?: string | string[]
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  value: any
}

export type DetailsRowProps = {
  rowKey: TableRowKey
  data: CellValue[]
}

export type CollapsibleRowDetailsProps = {
  classNames?: string | string[]
  title?: string
  head: CellValue[]
  rows: DetailsRowProps[]
  collapse?: [boolean, (newOpen: boolean) => void]
}

export type CollapsibleRowProps = {
  classNames?: string | string[]
  rowKey: TableRowKey
  cells: CellValue[]
  details: CollapsibleRowDetailsProps
}

export function CollapsibleTableRow(props: CollapsibleRowProps): JSX.Element {
  const classes = useStyle()

  const internalState = useState(false)
  const [open, setOpen] = props.details.collapse ?? internalState

  return (
    <>
      <TableRow
        key={props.rowKey}
        className={clsx(classes.root, props.classNames)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {
          props.cells.map(cell => (
            <TableCell className={clsx(cell.className)}>{cell.value}</TableCell>
          ))
        }
      </TableRow>
      <TableRow
        key={props.rowKey + "details"}
        className={clsx(props.details.classNames ?? props.classNames)}
      >
        <TableCell className={classes.detailsRow} colSpan={props.cells.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.box}>
              <Typography variant="h6" gutterBottom component="div">
                {props.details.title}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow key={props.rowKey + "detailsHead"}>
                    {props.details.head.map(field => (
                      <TableCell
                        align={field.align}
                        className={clsx(classes.boldCell, field.className)}
                      >{field.value}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.details.rows.map((row) => (
                    <TableRow key={row.rowKey}>
                      {row.data.map((field) => (
                        <TableCell align={field.align} className={clsx(field.className)}>{field.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}