import { gql, useQuery } from "@apollo/client";
import { QueryResult } from "../../gql/QueryResult";

const getEmployeesQuery = gql`
  query getEmployees(
    $start: DateTime!,
    $end: DateTime!
  ) {
    employee_get_active(
      employment_period_start: $start,
      employment_period_end: $end
    ) {
      id,
      first_name,
      last_name
    }
    employee_get_inactive(
      employment_period_start: $start,
      employment_period_end: $end
    ) {
      id,
      first_name,
      last_name
    }
  }
`

type Params = {
  start: Date
  end: Date
}

type Employee = {
  id: string
  first_name: string
  last_name: string
}

type Result = {
  employee_get_active: Employee[]
  employee_get_inactive: Employee[]
}

export type ParsedEmployee = {
  active: boolean
  uuid: string
  name: {
    first: string
    last: string
  }
  staffNumber?: number
}

function transformData(data: Result): ParsedEmployee[] {
  const merged: ParsedEmployee[] =
    data.employee_get_active.map(employee => ({
      active: true,
      uuid: employee.id,
      name: {
        first: employee.first_name,
        last: employee.last_name
      }
    })).concat(
      data.employee_get_inactive.map(employee => ({
        active: false,
        uuid: employee.id,
        name: {
          first: employee.first_name,
          last: employee.last_name
        }
      }))
    )
  return merged.sort((lhs, rhs) => lhs.name.last.localeCompare(rhs.name.last))
}

export function useGetEmployees(
  params: Params
): QueryResult<ParsedEmployee[], Result, Params> {
  const queryResult = useQuery<Result, Params>(
    getEmployeesQuery,
    {
      variables: params
    }
  )
  return new QueryResult(
    queryResult,
    transformData,
    {
      refetchVariables: params
    }
  )
}