import { TextField } from "@material-ui/core"
import { useState } from "react"
import { LoadingDialog, FormDialog } from "../../customUI"
import { useCreatePublicHoliday } from "./createPublicHoliday"
import { KeyboardDatePicker, PickerDate } from "../../util/dateFnsWrapper"
import { useError } from "../../Error/ErrorProvider"

export type Props = {
	open: boolean
	onClose: () => void
	refetchDate: Date
}

export function CreatePublicDialog(props: Props) {
	const errorHandler = useError()

	const [holidayDate, setHolidayDate] = useState<PickerDate>(null)
	const [holidayComment, setHolidayComment] = useState<string | undefined>()

	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
	const createPublicHoliday = useCreatePublicHoliday(props.refetchDate)

	return (
		<>
			<LoadingDialog open={loadingDialogOpen} />
			<FormDialog
				open={props.open}
				onClose={props.onClose}
				onSubmit={async () => {
					setLoadingDialogOpen(true)
					const result = await createPublicHoliday({
						date: holidayDate as Date,
						comment: holidayComment,
					})
					setLoadingDialogOpen(false)
					if (result.isError()) {
						errorHandler.pushError(result.error as Error)
					}
					setHolidayDate(new Date())
					setHolidayComment(undefined)
				}}
				data={{
					title: "Öffentlichen Feiertag anlegen",
					submitButtonText: "Feiertag anlegen",
				}}
				style={{
					gridTemplateColumns: 'auto'
				}}
			>
				<KeyboardDatePicker
					control={{
						state: holidayDate,
						setState: setHolidayDate,
					}}
					views={["date", "month", "year"]}
					label="Datum auswählen"
				/>
				<TextField
					label="Kommentar"
					multiline
					value={holidayComment}
					onChange={(event) => setHolidayComment(event.target.value)}
				></TextField>
			</FormDialog>
		</>
	)
}