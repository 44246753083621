import { Button, DialogActions, makeStyles, TextField, Typography } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { LoadingDialog } from "../customUI"
import { useError } from "../Error/ErrorProvider"
import { useLogin } from "./login"


const useStyles = makeStyles(theme => ({
	wrapper: {
		width: 'max-content',
		margin: 'auto',
		marginTop: theme.spacing(5),
		display: 'grid',
		gridTemplateColumns: '1',
		gap: theme.spacing(2),
		padding: theme.spacing(2),
	},
	title: {
		textAlign: 'center',
		color: theme.palette.primary.dark,
	},
	flower: {
		width: '80%',
		margin: 'auto',
		marginBottom: theme.spacing(2),
	},
}))

type Props = {
	onLogin: () => void
}

export default function LoginScreen(props: Props) {
	const classes = useStyles()
	const history = useHistory()
	const errorHandler = useError()

	const [mail, setMail] = useState('')
	const [password, setPassword] = useState('')
	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)

	const login = useLogin()

	if (history.location.pathname !== "/") {
		history.push("/")
	}

	return (
		<>
			<LoadingDialog open={loadingDialogOpen} />
			<div className={classes.wrapper}>
				<Typography className={classes.title} variant="h4">
					Kairos Admin Panel
				</Typography>
				<img
					className={classes.flower}
					src="../../BoragoFlower.svg"
					alt=""
				/>
				<TextField
					label="Mail"
					onChange={e => setMail(e.target.value)}
					value={mail}
				/>
				<TextField
					label="Password"
					type="password"
					onChange={e => setPassword(e.target.value)}
					value={password}
				/>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={async () => {
							setLoadingDialogOpen(true)
							const result = await login({ mail, password })
							setLoadingDialogOpen(false)
							if (result.isError()) {
								errorHandler.pushError(result.error as Error)
							}
							if (result.value === true) {
								props.onLogin()
							}
						}}
					>Login</Button>
				</DialogActions>
			</div>
		</>
	)
}