import { createContext, useContext, useState } from "react";

type TitleContextType = {
    title: string
    setTitle: (newHeadline: string, newTitle?: string) => void
} | null

const TitleContext = createContext<TitleContextType>(null)

function useTitle() {
    return useContext(TitleContext)
}

export function TitleProvider(props: any) {
    const [headline, setHeadline] = useState('')
    const ctx = {
        title: headline,
        setTitle: (newHeadline: string, newTitle?: string) => {
            setTimeout(() => {
                if (newHeadline !== headline) {
                    setHeadline(newHeadline)
                    const title = (newTitle !== undefined) ? newTitle : newHeadline
                    document.title = `KAP - ${title}`
                }
            }, 0)
        }
    }
    return (
        <TitleContext.Provider
            value={ctx}
        >{props.children}</TitleContext.Provider>
    )
}

export function Title(props: any) {
    const title = useTitle()
    return <>{title?.title}</>
}

export default useTitle