import { DialogActions, List, ListItem, ListItemSecondaryAction, ListItemText, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import BigNumber from "bignumber.js"
import { format } from "../util/dateFnsWrapper"
import { HoveredTableRow, Interval, StyledTable, TableHeadCell } from "../customUI"
import CollapsiblePanel from "../customUI/CollapsiblePanel"
import PrintView from "./PrintView"

type HolidayInterval = {
  start: Date
  end: Date
}

type Props = {
  employeeUUID: string
  collapsed?: boolean
  currentMonth: Date
  overtime: BigNumber.Value
  payouts: BigNumber.Value
  daysOfIllness: number
  holidays: HolidayInterval[]
}

export default function MonthSummary(props: Props): JSX.Element {
  return (
    <CollapsiblePanel
      collapsed={props.collapsed}
      title={`Übersicht ${format(props.currentMonth, "MMMM")}`}
    >
      <DialogActions>
        <PrintView month={props.currentMonth} employeeUUID={props.employeeUUID} />
      </DialogActions>
      <List>
        <ListItem>
          <ListItemText primary={"Überstunden:"} />
          <ListItemSecondaryAction>
            <Interval minutes={props.overtime} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary={"Auszahlungen:"} />
          <ListItemSecondaryAction>
            <Interval minutes={props.payouts} padLengthHours={0} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary={"Krankheitstage:"} />
          <ListItemSecondaryAction>
            {props.daysOfIllness}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Typography variant="h6">Urlaub</Typography>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>Von</TableHeadCell>
            <TableHeadCell>Bis</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (props.holidays.length === 0)
              ? <TableRow>
                <TableCell align="center" colSpan={2}>
                  <Typography>Keine Urlaube genommen.</Typography>
                </TableCell>
              </TableRow>
              : props.holidays.map((holiday, index) => (
                <HoveredTableRow key={`übersicht-${format(holiday.start, "dd-MM")}-${index}`}>
                  <TableCell>{format(holiday.start, "dd.MM.yyyy")}</TableCell>
                  <TableCell>{format(holiday.end, "dd.MM.yyyy")}</TableCell>
                </HoveredTableRow>
              ))
          }
        </TableBody>
      </StyledTable>
    </CollapsiblePanel>
  )
}