import { Typography, makeStyles, Paper, Collapse, IconButton } from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import clsx from "clsx"
import usePropsState from "../states/usePropsState"

const useStyle = makeStyles(theme => ({
  wrapper: {
    overflow: 'hidden',
    height: 'max-content',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  title: {
    display: 'inline',
  },
  arrow: {
    float: 'right',
    color: 'inherit',
    marginLeft: -30,
  },
  content: {
    padding: theme.spacing(2),
  },
}))

type Props = {
  className?: string | string[]
  title: string
  collapsed?: boolean
  children?: any
}

export default function CollapsiblePanel(props: Props): JSX.Element {
  const classes = useStyle()

  const [expanded, setExpanded] = usePropsState(!props.collapsed ?? true)

  return (
    <Paper className={clsx(classes.wrapper, props.className)}>
      <div
        className={classes.head}
      >
        <Typography className={classes.title} variant="h5">{props.title}</Typography>
        <IconButton
          className={classes.arrow}
          aria-label="expand row"
          size="small"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.content}>
          {props.children}
        </div>
      </Collapse>
    </Paper>
  )
}