import { Classes } from "./css"

const style: Classes = {
  container: {
    marginTop: '1.33em',
  },
  headline: {
    marginTop: '0',
    marginBottom: '3mm',
  },
  content: {
    marginLeft: '5mm',
  }
}

type SectionProps = {
  title?: string
  children?: any
}

export function Section(props: SectionProps) {
  return <div style={style.container}>
    {props.title !== undefined
      ? <h4 style={style.headline}>{props.title}</h4>
      : undefined}
    <div style={style.content}>{props.children}</div>
  </div>
}

export function SubSection(props: SectionProps) {
  return <div style={style.container}>
    {props.title !== undefined
      ? <h5 style={style.headline}>{props.title}</h5>
      : undefined}
      <div style={style.content}>{props.children}</div>
  </div>
}