import { gql, useMutation } from "@apollo/client";
import { Maybe } from "../../maybe";
import { refetchGetEmployeesQuery } from "../getEmployees";

const createPublicHolidayMutation = gql`
  mutation createPublicHoliday(
    $date: DateTime!,
    $comment: String
  ) {
    holiday_public_create(
      date: $date,
      comment: $comment
    ) {
      id
    }
  }
`

type Params = {
  date: Date
  comment?: string
}

type Result = {
  holiday_public_create: { id: string }[]
}

export function useCreatePublicHoliday(refetchDate: Date):
  (data: Params) => Promise<Maybe<boolean>> {
  const [mutation] = useMutation<Result, Params>(
    createPublicHolidayMutation,
  )

  return async (data: Params) => {
    try {
      const mutationResult = await mutation({
        variables: data,
        refetchQueries: [refetchGetEmployeesQuery(refetchDate)]
      })
      if (mutationResult.data != null) {
        return Maybe.Just(true)
      }
      return Maybe.None()
    } catch(e: any) {
      return Maybe.Error(e)
    }
  }
}