import { gql, useMutation } from "@apollo/client";
import { Maybe } from "../../maybe";
import { refetchGetEmployeesQuery } from "../getEmployees";

const createEmployeeMutation = gql`
  mutation createEmployee(
		$firstName: String!,
		$lastName: String!,
		$mail: String!,
		$nfcTag: String
	) {
		employee_create(
			first_name: $firstName,
			last_name: $lastName,
			mail: $mail,
			key: $nfcTag
		) {
			id
		}
	}
`

type Params = {
  firstName: string
  lastName: string
  mail: string
  nfcTag?: string
}

type Result = {
  employee_create: {
    id: string
  }
}

const createEmployeeAdditionalInfoMutation = gql`
  mutation createEmployeeAdditionalInfoMutation(
    $uuid: ID!,
    $staffNumber: Int,
    $birthDate: DateTime,
    $taxID: String,
    $address: employee_address_input
  ) {
    employee_edit(
      employee_id: $uuid,
      additional_info: {
        staff_number: $staffNumber,
        birth_date: $birthDate,
        tax_id: $taxID,
        address: $address
      }
    ) {
      id
    }
  }
`

type AdditionalInfoParams = {
  uuid: string
  staffNumber?: number
  birthDate?: Date
  taxID?: string
  address?: {
    street: string
    zip_code: string
    town: string
  }
}

type AdditionalInfoResult = {
  employee_edit: {
    id: string
  }
}

type MutationParams = Params & Omit<AdditionalInfoParams, "uuid">

export function useCreateEmployee():
  (data: MutationParams) => Promise<Maybe<string>> {
  const [mutation] = useMutation<Result, Params>(
    createEmployeeMutation,
  )
  const [additionalInfoMutation] = useMutation<
    AdditionalInfoResult,
    AdditionalInfoParams>(
      createEmployeeAdditionalInfoMutation,
    )

  return async (data: MutationParams) => {
    try {
      const mutationResult = await mutation({
        variables: data,
        refetchQueries: [refetchGetEmployeesQuery],
      })
      if (mutationResult.data != null) {
        const additionalInfoMutationResult = await additionalInfoMutation({
          variables: {
            uuid: mutationResult.data.employee_create.id,
            ...data,
          },
          refetchQueries: [refetchGetEmployeesQuery],
        })
        if (additionalInfoMutationResult.data != null) {
          return Maybe.Just(mutationResult.data.employee_create.id)
        }
      }
      return Maybe.None()
    } catch (e: any) {
      return Maybe.Error(e)
    }
  }
}