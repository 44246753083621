import { Tooltip, Typography, Zoom, makeStyles } from "@material-ui/core"
import BigNumber from "bignumber.js"
import { getHumanReadableHoliday, getHumanReadableHolidayDayAmounts, HolidayType } from "../gql"

export type tooltipHoliday = {
	type: HolidayType
	amount: BigNumber
	comment?: string
}

const useStyle = makeStyles(theme => ({
	tooltip: {
		fontSize: '1rem',
	},
	holidayWrapper: {
		fontSize: '1rem',
		padding: '4px',
	},
	typeTypography: {
		fontSize: '1.2em',
	},
}))

export type Props = {
	holidays: tooltipHoliday[]
	children: any
}

export function HolidayTooltip(props: Props) {
	const classes = useStyle()
	if (props.holidays.length > 0) {
		return (
			<Tooltip
				className={classes.tooltip}
				title={
					props.holidays.map((holiday, index) => {
						const comment = (holiday.comment != null && holiday.comment.length !== 0)
							? (<> Bemerkung: {holiday.comment} <br /> </>)
							: undefined
						const divider = (index !== 0)
							? <br />
							: undefined
						return (
							<div className={classes.holidayWrapper}>
								{divider}
								<Typography className={classes.typeTypography}>
									{getHumanReadableHoliday(holiday.type)}
								</Typography>
								<Typography variant="body1">
									Kontingent: {getHumanReadableHolidayDayAmounts(holiday.amount)}
								</Typography>
								<Typography variant="body2">
									{comment}
								</Typography>
							</div>
						)
					})
				}
				arrow
				placement="bottom-end"
				TransitionComponent={Zoom}
				enterDelay={500}
			>
				{props.children}
			</Tooltip >
		)
	} else {
		return props.children
	}
}