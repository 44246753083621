import { TableRow, TableBody, TableCell, TableHead, makeStyles, Switch, Button } from "@material-ui/core"
import BigNumber from "bignumber.js"
import { addDays } from "date-fns"
import format from "date-fns/format"
import { useEffect, useState } from "react"
import { CollapsibleTableRow, DetailsRowProps, Interval, StyledTable, TableHeadCell } from "../customUI"
import useArrayState from "../states/arrayState"
import { ParsedWorkingPeriod } from "./getDetails"
import { Edit as EditIcon } from "@material-ui/icons"
import { EditEventDialog } from "./EditEventDialog/EditEventDialog"

const maximumAllowedWorkingMinutes = 360

const useStyle = makeStyles(theme => ({
  cellBorder: {
    borderLeftWidth: theme.spacing(2),
    borderLeftColor: 'rgba(0, 0, 0, 0)',
    borderLeftStyle: 'solid',
  },
  highlightedRow: {
    backgroundColor: theme.palette.action.hover,
  },
  redRow: {
    borderLeftColor: 'red',
  },
  tableContainer: {
    width: 'max-content',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
}))

type Props = {
  displayedMonth: Date
  workingPeriods: ParsedWorkingPeriod[][]
  employeeUUID: string
}

export default function WorkingPeriods(props: Props): JSX.Element {
  const classes = useStyle()

  const [expanded, setExpanded] = useState(false)
  const tableExpanded = useArrayState<boolean>([])

  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState<[number, number]>([0, 0])

  useEffect(() => {
    const checkTableGloballyExpanded = () => {
      if (tableExpanded.state.length === 0) {
        return false
      }
      return tableExpanded.state.reduce((prev, curr) => prev && curr, true)
    }
    if (checkTableGloballyExpanded() !== expanded) {
      setExpanded(checkTableGloballyExpanded())
    }
  }, [tableExpanded.state, expanded])

  const setTableGloballyExpanded = (b: boolean) => {
    tableExpanded.state.forEach((_, idx) => tableExpanded.setStateAt(idx, b))
  }

  let totalWorkingTime: BigNumber = new BigNumber(0)
  let detailsCount = 0

  const tableWorkingPeriods = props.workingPeriods.map(
    (day, index) => {
      const currentDetailsCount = detailsCount
      let totalWorkingTimeOfDay: BigNumber = new BigNumber(0)
      const detailRows: DetailsRowProps[] = day.map((period, periodIndex) => {
        totalWorkingTime = totalWorkingTime.plus(period.durationInMinutes)
        totalWorkingTimeOfDay = totalWorkingTimeOfDay.plus(period.durationInMinutes)
        return {
          rowKey: period.startEventUUID,
          data: [
            { value: format(period.start, "HH:mm"), align: "right" },
            { value: format(period.end, "HH:mm"), align: "right" },
            { value: <Interval minutes={period.durationInMinutes} />, align: "right" },
            {
              value: <Button
                onClick={() => {
                  setSelectedPeriod([index, periodIndex])
                  setEditDialogOpen(true)
                }}
              ><EditIcon /></Button>
            }
          ]
        }
      })

      let className: string[] = (detailsCount % 2)
        ? [classes.highlightedRow]
        : []
      className.push(classes.cellBorder)
      const detailsClassName = className
      if (day.some(period => period.durationInMinutes.isGreaterThan(maximumAllowedWorkingMinutes))) {
        className.push(classes.redRow)
      }
      const tableRow: JSX.Element = <CollapsibleTableRow
        classNames={className}
        rowKey={index}
        cells={[
          { value: format(addDays(props.displayedMonth, index - 1), "dd.MM"), align: "right" },
          { value: <Interval minutes={totalWorkingTimeOfDay} />, align: "right" },
          { value: "" },
        ]}
        details={{
          classNames: detailsClassName,
          head: [
            { value: "Start" },
            { value: "Ende" },
            { value: "Gesamtzeit" },
            { value: undefined },
          ],
          rows: detailRows,
          collapse: [
            tableExpanded.state[currentDetailsCount],
            b => tableExpanded.setStateAt(currentDetailsCount, b),
          ],
        }}
      />
      tableExpanded.onItemInsert(detailsCount, false)
      detailsCount++
      return tableRow
    }
  )

  const tableContent = (props.workingPeriods.length === 0)
    ? (
      <TableRow key="noDataRow">
        <TableCell align="center" colSpan={4}>Keine Daten verfügbar.</TableCell>
      </TableRow>
    ) : tableWorkingPeriods

  return (
    <>
      <StyledTable
        size="small"
        classNames={{ container: classes.tableContainer }}
      >
        <TableHead>
          <TableRow>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell>Datum</TableHeadCell>
            <TableHeadCell>Dauer</TableHeadCell>
            <TableHeadCell>Kommentar</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.highlightedRow}>
            <TableCell>
              <Switch checked={expanded} onChange={_ => setTableGloballyExpanded(!expanded)} />
            </TableCell>
            <TableCell>Gesamter Monat</TableCell>
            <TableCell><Interval minutes={totalWorkingTime} /></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {tableContent}
        </TableBody>
      </StyledTable>
      <EditEventDialog
        key={`editDialog${selectedPeriod[0]}${selectedPeriod[1]}`}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={() => { }}
        period={props.workingPeriods?.[selectedPeriod[0]]?.[selectedPeriod[1]]}
        employeeUUID={props.employeeUUID}
        month={props.displayedMonth}
      />
    </>
  )
}