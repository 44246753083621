import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import BigNumber from "bignumber.js";
import { Maybe } from "../../../maybe";
import { refetchGetEmployeesQuery } from "../../getEmployees";

const editEmploymentPeriodMutation = gql`
  mutation editEmploymentPeriod(
    $id: ID!,
    $start: DateTime,
    $end: DateTime,
    $targetMinutes: employment_period_target_minutes_input,
    $dueHolidays: Int,
    $loan: Decimal
  ) {
    employment_period_edit(
      employment_period_id: $id,
      period_start: $start,
      period_end: $end,
      target_minutes: $targetMinutes,
      due_holidays: $dueHolidays,
      loan: $loan
    ) {
      id,
      employee_id,
      period_start,
      period_end,
      target_week_minutes,
      target_minutes {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
      },
      loan
    }
  }
`

type Params = {
  id: string,
  start?: Date,
  end?: Date,
  targetMinutes?: {
    monday?: number
    tuesday?: number
    wednesday?: number
    thursday?: number
    friday?: number
    saturday?: number
    sunday?: number
  },
  dueHolidays: number,
  loan?: BigNumber,
}

type Result = {
  employment_period_edit: {
    id: string
    employee_id: string
    period_start: string
    period_end: string
    target_week_minutes: number
    target_minutes: {
      monday?: number
      tuesday?: number
      wednesday?: number
      thursday?: number
      friday?: number
      saturday?: number
      sunday?: number
    }
    due_holidays_per_year: number
    loan: string
  }
}

type EmploymentPeriod = {
  id: number
  employeeUUID: string
  periodStart: Date
  periodEnd: Date
  targetWeekMinutes: number
  targetMinutes: {
    monday?: number
    tuesday?: number
    wednesday?: number
    thursday?: number
    friday?: number
    saturday?: number
    sunday?: number
  }
  dueHolidays: number
  loan: BigNumber
}

export function useEditEmploymentPeriod(
  options?: MutationHookOptions<Result, Params>
): (data: Params) => Promise<Maybe<EmploymentPeriod>> {
  const [mutation] = useMutation<Result, Params>(
    editEmploymentPeriodMutation,
    options,
  )

  return async (data: Params) => {
    try {
      const mutationResult = await mutation({
        variables: data,
        refetchQueries: [refetchGetEmployeesQuery],
      })
      if (mutationResult.data != null) {
        const resultData = mutationResult.data.employment_period_edit
        return Maybe.Just({
          id: parseInt(resultData.id),
          employeeUUID: resultData.employee_id,
          periodStart: new Date(resultData.period_start),
          periodEnd: new Date(resultData.period_end),
          targetWeekMinutes: resultData.target_week_minutes,
          targetMinutes: resultData.target_minutes,
          dueHolidays: resultData.due_holidays_per_year,
          loan: new BigNumber(resultData.loan),
        })
      }
      return Maybe.None()
    } catch (e: any) {
      return Maybe.Error(e)
    }
  }
}