import { gql, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { compareAsc, endOfDay, isEqual, max, minTime, startOfDay } from "date-fns";
import { QueryResult } from "../gql";

const getEmployeesQuery = gql`
  query getEmployees(
    $start: DateTime!,
    $end: DateTime!
  ) {
    employee_get_active(
      employment_period_start: $start,
      employment_period_end: $end
    ) {
      id,
      additional_info {
        staff_number
      },
      first_name,
      last_name,
      overtime,
      employment_periods {
        period_end
      }
    }
    employee_get_inactive(
      employment_period_start: $start,
      employment_period_end: $end
    ) {
      id,
      additional_info {
        staff_number
      },
      first_name,
      last_name,
      overtime,
      employment_periods {
        period_end
      }
    }
  }
`

type Params = {
  start: Date
  end: Date
}

type EmployeeData = {
  id: string
  additional_info: {
    staff_number?: number
  }
  first_name: string
  last_name: string
  overtime: string
  employment_periods: {
    period_end: string
  }[]
}

type Result = {
  employee_get_active: EmployeeData[]
  employee_get_inactive: EmployeeData[]
}

export type ParsedEmployeeData = {
  uuid: string
  staffNumber?: number
  name: string
  overtime: BigNumber
  leavingDate?: Date
}

type HookResult = {
  active: ParsedEmployeeData[]
  inactive: ParsedEmployeeData[]
}

const transformData = (data: Result) => {
  const result: HookResult = { active: [], inactive: [] }
  const minDate = new Date(minTime)
  const pushEmployee = (arr: ParsedEmployeeData[], employee: EmployeeData) => {
    let leavingDate: Date | undefined = employee.employment_periods.reduce(
      (maximum, curr) => max([maximum, new Date(curr.period_end)]),
      minDate,
    )
    if (isEqual(leavingDate, minDate)) {
      leavingDate = undefined
    }
    arr.push({
      uuid: employee.id,
      staffNumber: employee.additional_info.staff_number,
      name: `${employee.first_name} ${employee.last_name}`,
      overtime: new BigNumber(employee.overtime),
      leavingDate,
    })
  }

  for (const employee of data.employee_get_active ?? []) {
    pushEmployee(result.active, employee)
  }
  for (const employee of data.employee_get_inactive ?? []) {
    pushEmployee(result.inactive, employee)
  }
  const compareEmployees = (lhs: ParsedEmployeeData, rhs: ParsedEmployeeData) => {
    const dateComp = compareAsc(lhs.leavingDate ?? minDate, rhs.leavingDate ?? minDate)
    const staffComp = (lhs.staffNumber ?? 0) - (rhs.staffNumber ?? 0)
    return (dateComp === 0) ? staffComp : dateComp
  }
  result.active.sort(compareEmployees)
  result.inactive.sort(compareEmployees)
  return result
}

export function useGetEmployees(): QueryResult<HookResult, Result, Params> {
  const queryResult = useQuery<Result, Params>(
    getEmployeesQuery,
    {
      variables: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      },
      notifyOnNetworkStatusChange: true,
    },
  )
  return new QueryResult(
    queryResult,
    transformData,
    {
      refetchVariables: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      },
    },
  )
}