import { TextField, Typography } from "@material-ui/core";
import { FormDialog, LoadingDialog } from "../../../customUI";
import usePropsState from "../../../states/usePropsState";
import BigNumber from "bignumber.js";
import { format, KeyboardDatePicker, PickerDate } from "../../../util/dateFnsWrapper";
import { setDay } from "date-fns";
import { dayMask, EmploymentPeriod, employmentPeriodStyle, loanMask } from "../../employmentPeriodUtil";
import { useEffect, useState } from "react";
import { useEditEmploymentPeriod } from "./editEmploymentPeriod";
import { useError } from "../../../Error/ErrorProvider";
import { dayNumbers, TargetMinutes, TargetMinutesArray } from "../../targetMinutes";

type Props = {
  open: boolean
  onClose: () => void
  data: {
    employee: {
      uuid: string
      firstName: string
      lastName: string
    },
    period: EmploymentPeriod
  }
}

const bigNumberFormat: BigNumber.Format = {
  decimalSeparator: ',',
  groupSeparator: '.',
  suffix: ' €',
}

export function EditPeriodDialog(props: Props) {
  const classes = employmentPeriodStyle()
  const errorHandler = useError()

  const [start, setStart] = usePropsState<PickerDate>(props.data.period.start)
  const [end, setEnd] = usePropsState<PickerDate>(props.data.period.end)
  const [targetMinutes, setTargetMinutes] = useState<
    TargetMinutesArray>([0,0,0,0,0,0,0])
  useEffect(() => {
    setTargetMinutes(TargetMinutes.fromGQL(props.data.period.targetMinutes))
  }, [props.data.period.targetMinutes])
  const [dueHolidays, setDueHolidays] = usePropsState(props.data.period.dueHolidays)
  BigNumber.config({ FORMAT: bigNumberFormat })
  const [loan, setLoan] = usePropsState(props.data.period.loan.toFormat(2))

  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)

  const editPeriod = useEditEmploymentPeriod()

  console.log({ targetMinutes })

  return (
    <>
      <LoadingDialog open={loadingDialogOpen} />
      <FormDialog
        open={props.open}
        onClose={props.onClose}
        onSubmit={async () => {
          setLoadingDialogOpen(true)
          const params = {
            id: props.data.period.id + "",
            start: (start !== null) ? start : undefined,
            end: (end !== null) ? end : undefined,
            targetMinutes: TargetMinutes.sanatizeGQL(TargetMinutes.toGQL(targetMinutes)),
            dueHolidays: dueHolidays,
            loan: new BigNumber(loan
              .replaceAll('.', '')
              .replace(' €', '')
              .replace(',', '.')
            ),
          }
          console.log({params, targetMinutes})
          const result = await editPeriod(params)
          setLoadingDialogOpen(false)
          if (result.isError()) {
            errorHandler.pushError(result.error as Error)
          }
        }}
        data={{
          title: "Beschäftigungszeit bearbeiten",
          submitButtonText: "Ändern",
        }}
        style={{
          gridTemplateColumns: '1fr',
        }}
      >
        <div>
          <Typography variant="caption" color="textSecondary">Angestellter</Typography>
          <Typography
            variant="h6"
          >{props.data.employee.firstName} {props.data.employee.lastName}</Typography>
        </div>
        <Typography variant="h6">
          Arbeitszeitraum
        </Typography>
        <KeyboardDatePicker
          control={{
            state: start,
            setState: setStart
          }}
          views={["date", "month", "year"]}
          label="Beginndatum auswählen"
        />
        <KeyboardDatePicker
          control={{
            state: end,
            setState: setEnd
          }}
          views={["date", "month", "year"]}
          label="Enddatum auswählen"
        />
        <Typography variant="h6">
          Arbeitszeiten
        </Typography>
        <div className={classes.timeGrid}>
          {dayNumbers.map(day =>
            <TextField
              key={`editPeriodTargetMinutes-${props.data.period.id}-${day}`}
              label={format(setDay(new Date(), day + 1), "iii")}
              type='time'
              value={TargetMinutes.toHourString(targetMinutes[day])}
              onChange={e => {
                const temp = targetMinutes
                temp[day] = TargetMinutes.parseHourString(e.target.value) ?? null
                setTargetMinutes([...temp])
              }}
            />
          )}
        </div>
        <TextField
          className={classes.halfWidth}
          label="Urlaubstage im Jahr"
          value={dueHolidays + ""}
          InputProps={{
            inputComponent: dayMask,
          }}
          onChange={e => {
            let parsed = parseInt(
              e.target.value
                .replace(' Tage', '')
                .replaceAll('.', '')
            )
            if (isNaN(parsed)) {
              parsed = 0
            }
            setDueHolidays(parsed)
          }}
          inputProps={{
            className: classes.numberInput,
          }}
        />
        <Typography variant="h6">
          Gehalt
        </Typography>
        <TextField
          className={classes.halfWidth}
          label="Gehalt pro Monat"
          value={loan}
          onChange={e => setLoan(e.target.value)}
          InputProps={{
            inputComponent: loanMask,
          }}
          inputProps={{
            className: classes.numberInput,
          }}
        />
      </FormDialog>
    </>
  )
}