import { createMuiTheme, ThemeProvider } from "@material-ui/core"
import { deDE } from '@material-ui/core/locale'
import { deepPurple, teal } from "@material-ui/core/colors"
import { TitleProvider } from "./TitleProvider"
import { BrowserRouter as Router } from "react-router-dom"
import { InMemoryCache, ApolloClient, ApolloProvider, HttpLink } from "@apollo/client"
import ErrorProvider from "./Error/ErrorProvider"
import { ConfirmDialogProvider } from "./ConfirmDialogProvider"
import { MuiPickerProvider } from "./util/dateFnsWrapper"

const theme = createMuiTheme({
	palette: {
		primary: {
			main: teal[700],
		},
		secondary: {
			main: deepPurple[500],
		},
	},
}, deDE)

async function fetcher(url: RequestInfo, params: RequestInit): Promise<Response> {
	const sessionID = localStorage.getItem("sessionID") ?? ""
	const headers: { [key: string]: any } = { ...params.headers }
	headers["X-Session-ID"] = sessionID
	params.headers = headers
	const responsePromise = fetch(url, params)
	const response = await responsePromise
	const newSessionID = response.headers.get("X-Session-ID")
	if (newSessionID !== sessionID) {
		localStorage.setItem("sessionID", newSessionID ?? "")
	}
	return responsePromise
}

const API_URL = process.env.NODE_ENV === 'development'
	? `//${window.location.hostname}:8080/graphql`
	: `//${window.location.hostname}/graphql`;

const link = new HttpLink({
	uri: API_URL,
	fetch: fetcher,
})

const client = new ApolloClient({
	link: link,
	cache: new InMemoryCache(),
})

type Props = {
	children: any
}


function MetaWrapper(props: Props) {
	return (
		<ThemeProvider theme={theme}>
			<ApolloProvider client={client}>
				<ErrorProvider>
					<ConfirmDialogProvider>
						<TitleProvider>
							<MuiPickerProvider>
								<Router>
									{props.children}
								</Router>
							</MuiPickerProvider>
						</TitleProvider>
					</ConfirmDialogProvider>
				</ErrorProvider>
			</ApolloProvider>
		</ThemeProvider>
	)
}

export default MetaWrapper