import { useState } from "react";
import { FormDialog, LoadingDialog } from "../../../customUI";
import { useCreateEmploymentPeriod } from "./createEmploymentPeriod";
import { Typography, TextField } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { format, KeyboardDatePicker, PickerDate } from "../../../util/dateFnsWrapper";
import { setDay } from "date-fns";
import { dayMask, employmentPeriodStyle, loanMask } from "../../employmentPeriodUtil";
import { useError } from "../../../Error/ErrorProvider";
import { dayNumbers, TargetMinutes, TargetMinutesArray } from "../../targetMinutes";

type Props = {
  open: boolean
  onClose: () => void
  data: {
    employee: {
      uuid: string
      firstName: string
      lastName: string
    }
  }
}

export function CreatePeriodDialog(props: Props) {
  const classes = employmentPeriodStyle()
  const errorHandler = useError()

  const [start, setStart] = useState<PickerDate>(null)
  const [end, setEnd] = useState<PickerDate>(null)
  const [targetMinutes, setTargetMinutes] = useState<
    TargetMinutesArray>([0, 0, 0, 0, 0, 0, 0])
  const [dueHolidays, setDueHolidays] = useState("0")
  const [loan, setLoan] = useState<string>("0,00 €")

  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const createPeriod = useCreateEmploymentPeriod()

  return (
    <>
      <LoadingDialog open={loadingDialogOpen} />
      <FormDialog
        open={props.open}
        onClose={props.onClose}
        onSubmit={async () => {
          setLoadingDialogOpen(true)
          const result = await createPeriod({
            id: props.data.employee.uuid,
            start: start as Date,
            end: end as Date,
            targetMinutes: TargetMinutes.sanatizeGQL(TargetMinutes.toGQL(targetMinutes)),
            loan: new BigNumber(loan
              .replaceAll('.', '')
              .replace(' €', '')
              .replace(',', '.')
            ),
            holidays: parseInt(dueHolidays),
          })
          setLoadingDialogOpen(false)
          if (result.isError()) {
            errorHandler.pushError(result.error as Error)
          }
        }}
        data={{
          title: "Beschäftigungszeit anlegen",
          submitButtonText: "Anlegen",
        }}
        style={{
          gridTemplateColumns: '1fr',
        }}
      >
        <div>
          <Typography variant="caption" color="textSecondary">Angestellter</Typography>
          <Typography
            variant="h6"
          >{props.data.employee.firstName} {props.data.employee.lastName}</Typography>
        </div>
        <Typography variant="h6">
          Arbeitszeitraum
        </Typography>
        <KeyboardDatePicker
          control={{
            state: start,
            setState: setStart,
          }}
          views={["date", "month", "year"]}
          label="Beginndatum auswählen"
        />
        <KeyboardDatePicker
          control={{
            state: end,
            setState: setEnd,
          }}
          views={["date", "month", "year"]}
          label="Enddatum auswählen"
        />
        <Typography variant="h6">
          Arbeitszeiten
        </Typography>
        <div className={classes.timeGrid}>
          {dayNumbers.map(day =>
            <TextField
              label={format(setDay(new Date(), day + 1), "iii")}
              type='time'
              value={TargetMinutes.toHourString(targetMinutes[day])}
              onChange={e => {
                const temp = targetMinutes
                temp[day] = TargetMinutes.parseHourString(e.target.value) ?? null
                setTargetMinutes([...temp])
              }}
            />
          )}
        </div>
        <TextField
          className={classes.halfWidth}
          label="Urlaubstage im Jahr"
          value={dueHolidays}
          InputProps={{
            inputComponent: dayMask,
          }}
          onChange={e => setDueHolidays(e.target.value)}
          inputProps={{
            className: classes.numberInput,
          }}
        />
        <Typography variant="h6">
          Gehalt
        </Typography>
        <TextField
          className={classes.halfWidth}
          label="Gehalt pro Monat"
          value={loan}
          onChange={e => setLoan(e.target.value)}
          InputProps={{
            inputComponent: loanMask,
          }}
          inputProps={{
            className: classes.numberInput,
          }}
        />
      </FormDialog>
    </>
  )
}