import { gql, useMutation } from "@apollo/client";
import { Maybe } from "../maybe";
import { refetchGetEmployeesQuery } from "./getEmployees";

const deleteEmployeeMutation = gql`
  mutation deleteEmployee(
		$id: ID!
	) {
		employee_delete(
			employee_id: $id
		)
	}
`

type Params = {
  id: string
}

type Result = {
  employee_delete: boolean
}

export function useDeleteEmployee():
  (data: Params) => Promise<Maybe<boolean>> {
  const [mutation] = useMutation<Result, Params>(
    deleteEmployeeMutation,
  )

  return async (data: Params) => {
    try {
    const mutationResult = await mutation({
      variables: data,
      refetchQueries: [refetchGetEmployeesQuery],
    })
    if (mutationResult.data != null) {
      return Maybe.Just(mutationResult.data.employee_delete)
    }
    return Maybe.None()
  } catch(e: any) {    
    return Maybe.Error(e)
  }
  }
}