import { makeStyles } from "@material-ui/core";
import { createContext, useContext, useState } from "react";
import { FormDialog } from "./customUI";

const useStyle = makeStyles((theme) => ({
	title: {
		backgroundColor: theme.palette.secondary.main,
	},
	button: {
		'&:hover:': theme.palette.secondary.dark,
	}
}))

type ConfirmDialogContextType = {
	open: boolean
	setOpen: (newState: boolean) => void
	title: string | undefined
	setTitle: (newTitle: string | undefined) => void
	content: JSX.Element
	setContent: (newContent: JSX.Element) => void
	submitFunction: (data: any) => void
	setSubmitFunction: (newFunction: () => void) => void
	submitText: string | undefined
	setSubmitText: (newText: string | undefined) => void
	openWithContent: (data: {
		title?: string,
		content?: JSX.Element,
		submitFunction: () => void,
		submitText?: string
	}) => void
} | null

const ConfirmDialogContext = createContext<ConfirmDialogContextType>(null)

export function useConfirmDialog() {
	return useContext(ConfirmDialogContext)
}

export function ConfirmDialogProvider(props: any) {
	const [open, setOpen] = useState<boolean>(false)
	const [title, setTitle] = useState<string | undefined>()
	const [content, setContent] = useState<JSX.Element>(<></>)
	const [submitFunction, setSubmitFunction] = useState<{ func: () => void }>({ func: () => { } })
	const [submitText, setSubmitText] = useState<string | undefined>()

	const ctx = {
		open,
		setOpen: (newState: boolean) => {
			if (newState !== open) {
				setOpen(newState)
			}
		},
		title,
		setTitle: (newTitle: string | undefined) => {
			if (newTitle !== title) {
				setTitle(newTitle)
			}
		},
		content,
		setContent: (newContent: JSX.Element) => {
			if (content !== newContent) {
				setContent(newContent)
			}
		},
		submitFunction: submitFunction.func,
		setSubmitFunction: (newFunction: () => void) => {
			if (submitFunction.func !== newFunction) {
				setSubmitFunction({ func: newFunction })
			}
		},
		submitText,
		setSubmitText: (newText: string | undefined) => {
			if (submitText !== newText) {
				setSubmitText(newText)
			}
		},
		openWithContent: (data: {
			title?: string,
			content?: JSX.Element,
			submitFunction: () => void,
			submitText?: string
		}) => {
			setOpen(true)
			if (data.title !== title) {
				setTitle(data.title)
			}
			if ((data.content ?? <></>) !== content) {
				setContent(data.content ?? <></>)
			}
			if (data.submitFunction !== submitFunction.func) {
				setSubmitFunction({ func: data.submitFunction })
			}
			if (data.submitText !== submitText) {
				setSubmitText(data.submitText)
			}
		}
	}

	const classes = useStyle()
	return (
		<>
			<ConfirmDialogContext.Provider
				value={ctx}
			>
				<div>
					{props.children}
				</div>
			</ConfirmDialogContext.Provider>
			<FormDialog
				open={open}
				onClose={() => {
					setOpen(false)
				}}
				onSubmit={submitFunction.func}
				data={{
					title: title ?? "Sind Sie sicher?",
					submitButtonText: submitText ?? "Ja, ich bin mir sicher",
				}}
				style={{
					title: classes.title,
					buttonColor: "secondary",
					gridTemplateColumns: 'auto',
				}}
			>{content}</FormDialog>
		</>
	)
}

export function ConfirmDialog() {

}