type StoreIndex = {
	keyPath: string | string[]
	parameters?: IDBIndexParameters
}

type ObjectStoreDefinition = {
	keyPath?: string | string[]
	autoIncrement?: boolean
	indices?: { [key: string]: StoreIndex }
}


type OpenOptions = {
	onBlocked?: IDBOpenDBRequest['onblocked']
	stores: { [key: string]: ObjectStoreDefinition }
}

/*
1.	open connection
	- for each object store
	- check if the object store matches the parameter
	- if false
		- close database and update
	- if true
		- return database
*/

/**
 * Example:
 *  const db = await IndexedDB.open("test", {
 *   	stores: {
 *     		"test": {
 *				autoIncrement: true,
 *			},
 *   	}
 * 	})
 */

export class IndexedDB {
	private db: IDBDatabase

	static open(name: string, options: OpenOptions): Promise<IndexedDB> {
		const _options = {
			onBlocked: () => { },
			version: 1,
			...options,
		}
		console.log(_options)
		return new Promise((resolve, reject) => {
			const openRequest = indexedDB.open(name, _options.version)
			openRequest.onblocked = _options.onBlocked
			openRequest.onupgradeneeded = function (event) {
				const stores = options.stores
				const db = this.result
				for (const name in stores) {
					if (!stores.hasOwnProperty(name)) {
						continue
					}
					const { indices, ...rest } = stores[name]
					const store = db.createObjectStore(name, rest)
					for (const indexName in indices) {
						if (!indices.hasOwnProperty(indexName)) {
							continue
						}
						const index = indices[indexName]
						store.createIndex(indexName, index.keyPath, index.parameters)
					}
				}
			}
			openRequest.onsuccess = function (event) {
				resolve(new IndexedDB(this.result))
			}
			openRequest.onerror = function (event) {
				reject(this.error)
			}
		})
	}

	constructor(db: IDBDatabase) {
		this.db = db
	}

	public tx(executor: (tx: any) => void, storeNames: string | Iterable<string>, mode?: IDBTransactionMode): void {
		const tx = this.db.transaction(storeNames, mode)
		//executor(new Transaction(tx))
		executor(tx)
	}

	//todo support options and throw if options differ
	public createObjectStore(tableName: string) {
		const tx = this.db.transaction("", "versionchange")
		if (tx.objectStoreNames.contains(tableName)) {
			return
		}
		tx.db.createObjectStore(tableName)
		console.log(tx)
	}

	public createObjectStores(tableNames: string[]) {
		for (const tableName in tableNames) {
			this.createObjectStore(tableName)
		}
	}

	public getValue(tableName: string, id: any): Promise<any> {
		const tx = this.db.transaction(tableName, "readonly")
		const store = tx.objectStore(tableName)
		const request = store.get(id)

		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				resolve(this.result)
			}
			request.onerror = function (event) {
				reject(this.error)
			}
		})
	}

	public putValue(tableName: string, value: object, key?: any): Promise<IDBValidKey> {
		const tx = this.db.transaction(tableName, "readwrite")
		const store = tx.objectStore(tableName)
		const request = store.put(value, key)
		console.log(tx)
		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				resolve(this.result)
			}
			request.onerror = function (event) {
				reject(this.error)
			}
		})
	}
}