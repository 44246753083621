import deLocale from "date-fns/locale/de"
import { addMonths, format as formatDateFns, parse, subMonths } from 'date-fns'
import { KeyboardDatePicker as MuiKeyboardDatePicker, KeyboardDatePickerProps as MuiKeyboardDatePickerProps, KeyboardDateTimePicker as MuiKeyboardDateTimePicker, KeyboardDateTimePickerProps as MuiKeyboardDateTimePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { Button, ButtonGroup, makeStyles } from "@material-ui/core"
import * as Icons from '@material-ui/icons'
import isValid from "date-fns/isValid"
import { useRegisterKeyboardInput } from "./effects"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { useState } from "react"

export function format(
  date: Date | number,
  format: string,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string {
  return formatDateFns(
    date,
    format,
    {
      locale: deLocale,
      ...options,
    },
  )
}

export const MuiPickerProvider: React.FC = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
    {props.children}
  </MuiPickersUtilsProvider>
)

type ControlledPickerProps = {
  format?: string
  state: PickerDate
  setState: (d: PickerDate) => void
}

type ControlledKeyboardPickerProps<T> = {
  control: ControlledPickerProps
} & Omit<T, "onChange" | "value">

type KeyboardDatePickerProps =
  ControlledKeyboardPickerProps<MuiKeyboardDatePickerProps> |
  MuiKeyboardDatePickerProps

export type PickerDate = MaterialUiPickersDate

function parseDate(
  format: string,
  setState: (d: PickerDate) => void,
  setError: (b: boolean) => void,
  correctTimeZoneOffset?: boolean
):
  (date: PickerDate, value?: string | null | undefined) => void {
  return (_, dateString) => {
    if (dateString != null) {
      const parsedResult = parse(dateString, format, new Date())
      if (!isValid(parsedResult)) {
        setError(true)
        return
      }
      setError(false)
      if (correctTimeZoneOffset ?? false) {
        parsedResult.setTime(
          parsedResult.getTime() - parsedResult.getTimezoneOffset() * 1000 * 60
        )
      }
      setState(parsedResult)
    } else {
      setState(null)
    }
  }
}


export function KeyboardDatePicker(props: KeyboardDatePickerProps): JSX.Element {
  const [error, setError] = useState(false)
  let picker: JSX.Element
  if (props.hasOwnProperty("control")) {
    const cProps = props as ControlledKeyboardPickerProps<MuiKeyboardDatePickerProps>
    const format = cProps.control.format ?? "dd.MM.yyyy"
    picker = <MuiKeyboardDatePicker
      cancelLabel="zurück"
      {...props}
      error={error}
      format={format}
      value={cProps.control.state}
      onChange={parseDate(format, cProps.control.setState, setError, true)}
    />
  } else {
    const ucProps = props as MuiKeyboardDatePickerProps
    picker = <MuiKeyboardDatePicker cancelLabel="zurück" {...ucProps} />
  }
  return picker
}

type KeyboardDateTimePickerProps =
  ControlledKeyboardPickerProps<MuiKeyboardDateTimePickerProps> |
  MuiKeyboardDateTimePickerProps

export function KeyboardDateTimePicker(props: KeyboardDateTimePickerProps): JSX.Element {
  const [error, setError] = useState(false)
  let picker: JSX.Element
  if (props.hasOwnProperty("control")) {
    const cProps = props as ControlledKeyboardPickerProps<MuiKeyboardDateTimePickerProps>
    const format = cProps.control.format ?? "HH:mm dd.MM.yyyy"
    picker = <MuiKeyboardDateTimePicker
      cancelLabel="zurück"
      variant="dialog"
      ampm={false}
      openTo="hours"
      {...props}
      error={error}
      format={format}
      value={cProps.control.state}
      onChange={parseDate(format, cProps.control.setState, setError)}
    />
  } else {
    const ucProps = props as MuiKeyboardDateTimePickerProps
    picker = <MuiKeyboardDateTimePicker cancelLabel="zurück" {...ucProps} />
  }
  return picker
}

const useKeyboardMonthPickerStyles = makeStyles(theme => ({
  datePickerWrapper: {
    padding: theme.spacing(1),
  },
}))

type MonthPickerProps = {
  date: PickerDate
  setDate: (d: PickerDate) => void
  keyboardEventInput?: {
    prev: string
    next: string
  }
}

export function KeyboardMonthPicker(props: MonthPickerProps): JSX.Element {
  const classes = useKeyboardMonthPickerStyles()
  const currentDate = props.date ?? new Date()

  const setPrevMonth = () => props.setDate(subMonths(currentDate, 1))
  const setNextMonth = () => props.setDate(addMonths(currentDate, 1))

  useRegisterKeyboardInput(
    props.keyboardEventInput?.prev ?? "",
    setPrevMonth
  )
  useRegisterKeyboardInput(
    props.keyboardEventInput?.next ?? "",
    setNextMonth
  )

  return (
    <ButtonGroup
      key={format(currentDate, "yyyy.MM")}
      variant="contained"
      color="primary"
    >
      <Button
        onClick={setPrevMonth}
      >
        <Icons.ArrowBack />
      </Button>
      <div className={classes.datePickerWrapper}>
        <KeyboardDatePicker
          control={{
            format: "MM.yyyy",
            state: props.date,
            setState: props.setDate
          }}
          openTo="month"
          views={["month", "year"]}
          label="Monat auswählen"
        />
      </div>
      <Button
        onClick={setNextMonth}
      >
        <Icons.ArrowForward />
      </Button>
    </ButtonGroup>
  )
}