import { TextField, TableRow, TableCell, makeStyles, TableBody, Typography, TableHead, Button, Fab } from "@material-ui/core";
import { FormDialog, LoadingDialog } from "../../customUI/dialogs";
import usePropsState from "../../states/usePropsState"
import * as Icons from '@material-ui/icons';
import { DeleteIconRed, StyledTable, TableHeadCell } from "../../customUI";
import { useConfirmDialog } from "../../ConfirmDialogProvider";
import { useDeleteEmploymentPeriod } from "./deleteEmploymentPeriod";
import { useState } from "react";
import { CreatePeriodDialog } from "./CreatePeriodDialog/CreatePeriodDialog";
import { EditPeriodDialog } from "./EditPeriodDialog/EditPeriodDialog";
import { format, KeyboardDatePicker } from "../../util/dateFnsWrapper";
import BigNumber from "bignumber.js";
import { useEditEmployee } from "./editEmployee";
import { ParsedEmployee } from "../getEmployees";
import { useError } from "../../Error/ErrorProvider";
import clsx from "clsx";
import { useCreateEmployee } from "./createEmployee";
import { compareDesc } from "date-fns";

type Props = {
  onClose(): void
  open: boolean
  employee: ParsedEmployee
  createNew: boolean
}

const useStyle = makeStyles((theme) => ({
  topSpacing: {
    marginTop: theme.spacing(2),
  },
  wideCell: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  smallTableCell: {
    padding: 0,
  },
  tableContainer: {
    position: 'relative',
  },
  addButton: {
    zIndex: 1,
    position: "absolute",
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(2),
  },
  addressWrapper: {
    display: "grid",
    gridTemplateColumns: "30% auto",
    gap: theme.spacing(1),
  },
}))

const dummyPeriod = {
  id: -1,
  start: new Date(),
  end: new Date(),
  targetWeekMinutes: 0,
  targetMinutes: {},
  dueHolidays: 0,
  loan: new BigNumber(0),
}

export function EditDialog(props: Props) {
  const classes = useStyle()
  const confirmDialog = useConfirmDialog()
  const errorHandler = useError()

  const [firstName, setFirstName] = usePropsState(props.employee.name.first)
  const [lastName, setLastName] = usePropsState(props.employee.name.last)
  const [mail, setMail] = usePropsState(props.employee.mail)
  const [nfcTag, setNfcTag] = usePropsState(props.employee.nfcTag)

  const [staffNumber, setStaffNumber] = usePropsState<string | number>(props.employee.staffNumber ?? "")
  const [staffNumberError, setStaffNumberError] = useState<boolean>(false)
  const [birthDate, setBirthDate] = usePropsState(props.employee.birthDate ?? null)
  const [birthDateError, setBirthDateError] = useState<boolean>(false)
  const [taxID, setTaxID] = useState<string>("")

  const [addressStreet, setAddressStreet] = usePropsState<string>(props.employee.address?.street ?? "")
  const [addressZIP, setAddressZIP] = usePropsState<string>(props.employee.address?.zipCode ?? "")
  const [addressTown, setAddressTown] = usePropsState<string>(props.employee.address?.town ?? "")

  const [openCreate, setOpenCreate] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [periodID, setPeriodID] = useState<number>(-1)
  const foundPeriod = props.employee?.employmentPeriods.find(period => period.id === periodID)
  const selectedPeriod = foundPeriod ?? dummyPeriod

  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)

  const removeStateValuesAfterCreate = () => {
    setFirstName("")
    setLastName("")
    setMail("")
    setNfcTag(undefined)
    setStaffNumber("")
    setBirthDate(null)
    setTaxID("")
    setAddressStreet("")
    setAddressZIP("")
    setAddressTown("")
  }

  const createEmployee = useCreateEmployee()
  const editEmployee = useEditEmployee()
  const deletePeriod = useDeleteEmploymentPeriod()

  const editFields = (
    <>
      <div className={classes.wideCell}>
        <Typography
          variant="caption"
          color="textSecondary"
        >
          Beschäftigungszeiten
        </Typography>
        <div className={clsx(classes.topSpacing, classes.tableContainer)}>
          <Fab
            className={classes.addButton}
            color="secondary"
            size="small"
            onClick={() => setOpenCreate(true)}
          >
            <Icons.Add />
          </Fab>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableHeadCell></TableHeadCell>
                <TableHeadCell>Beginn</TableHeadCell>
                <TableHeadCell>Ende</TableHeadCell>
                <TableHeadCell></TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.employee?.employmentPeriods
                .sort((lhs, rhs) => compareDesc(lhs.start, rhs.start))
                .map((period) => (
                  <TableRow key={"Employmenperiod" + period.id}>
                    <TableCell></TableCell>
                    <TableCell>{format(period.start, "dd.LL.yyy")}</TableCell>
                    <TableCell>{format(period.end, "dd.LL.yyyy")}</TableCell>
                    <TableCell
                      className={classes.smallTableCell}
                    >
                      <Button
                        onClick={() => {
                          setPeriodID(period.id)
                          setOpenEdit(true)
                        }}
                      >
                        <Icons.Edit />
                      </Button>
                    </TableCell>
                    <TableCell
                      className={classes.smallTableCell}
                    >
                      <Button
                        onClick={() => {
                          confirmDialog?.openWithContent({
                            title: "Beschäftigungszeit löschen?",
                            content:
                              <div>
                                Sind sie sicher, dass der Beschäftigungszeitraum von
                                <b> {`${props.employee?.name.first} ${props.employee?.name.last}`} </b>
                                vom
                                <b> {format(period.start, "dd.LL.yyy")} </b>
                                bis
                                <b> {format(period.end, "dd.LL.yyy")} </b>
                                gelöscht werden soll?
                              </div>,
                            submitFunction: async () => {
                              setLoadingDialogOpen(true)
                              const result = await deletePeriod({ id: period.id + "" })
                              setLoadingDialogOpen(false)
                              if (result.isError()) {
                                errorHandler.pushError(result.error as Error)
                              }
                            },
                            submitText: "Löschen",
                          })
                        }}
                      >
                        <DeleteIconRed />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </StyledTable>
        </div>
      </div>
    </>
  )

  const editDialogs = (
    <>
      <CreatePeriodDialog
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        data={{
          employee: {
            uuid: props.employee?.uuid ?? "",
            firstName: props.employee?.name.first ?? "",
            lastName: props.employee?.name.last ?? "",
          },
        }}
      />
      <EditPeriodDialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        data={{
          employee: {
            uuid: props.employee?.uuid ?? "",
            firstName: props.employee?.name.first ?? "",
            lastName: props.employee?.name.last ?? "",
          },
          period: selectedPeriod,
        }}
      />
    </>
  )

  return (
    <>
      <LoadingDialog open={loadingDialogOpen} />
      <FormDialog
        open={props.open}
        onClose={props.onClose}
        onSubmit={async () => {
          setLoadingDialogOpen(true)
          const result = (props.createNew ?? false)
            ? await createEmployee({
              firstName,
              lastName,
              mail,
              nfcTag: (nfcTag === "") ? undefined : nfcTag,
              taxID: (taxID === "") ? undefined : taxID,
              staffNumber: (staffNumber === "") ? undefined : (staffNumber as number),
              birthDate: birthDate ?? undefined,
              address: (addressStreet)
                ? {
                  street: addressStreet,
                  zip_code: addressZIP,
                  town: addressTown,
                }
                : undefined,
            })
            : await editEmployee({
              id: props.employee?.uuid ?? "",
              firstName,
              lastName,
              mail,
              nfcTag: (nfcTag === "") ? null : nfcTag,
              taxID: (taxID === "") ? null : taxID,
              staffNumber: (staffNumber === "") ? null : (staffNumber as number),
              birthDate: birthDate ?? null,
              address: {
                street: addressStreet,
                zip_code: addressZIP,
                town: addressTown,
              }
            })
          setLoadingDialogOpen(false)
          if (result.isError()) {
            errorHandler.pushError(result.error as Error)
          }
          if (props.createNew) {
            removeStateValuesAfterCreate()
          }
        }}
        submitDisabled={staffNumberError || birthDateError}
        data={{
          title: props.createNew
            ? "Neuen Angestellten anlegen"
            : `Angestellter ${props.employee?.name.first} ${props.employee?.name.last}`,
          submitButtonText: props.createNew
            ? "Anlegen"
            : "Daten ändern",
        }}
      >
        <TextField
          label="Vorname"
          autoComplete="given-name"
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
        />
        <TextField
          label="Nachname"
          autoComplete="family-name"
          onChange={e => setLastName(e.target.value)}
          value={lastName}
        />
        <TextField
          label="Mailadresse"
          autoComplete="email"
          onChange={e => setMail(e.target.value)}
          value={mail}
        />
        <TextField
          label="NfcTag"
          onChange={e => setNfcTag(e.target.value)}
          value={nfcTag}
        />
        <div className={classes.wideCell}>
          <Typography variant="subtitle2">Zusätzliche Informationen</Typography>
        </div>
        <TextField
          label="Mitarbeiternummer"
          error={staffNumberError}
          onChange={e => {
            const value = e.target.value
            if (/^[0-9]{0,5}$/.test(value)) {
              setStaffNumberError(false)
              if (value.length === 0) {
                setStaffNumber("")
              } else {
                setStaffNumber(parseInt(value))
              }
            } else {
              setStaffNumberError(true)
              setStaffNumber(value)
            }
          }}
          value={staffNumber}
          helperText={
            staffNumberError
              ? "Die Mitarbeiternummer muss eine Zahl mit maximal 5 Stellen sein"
              : ""
          }
        />
        <KeyboardDatePicker
          control={{
            state: birthDate,
            setState: setBirthDate,
          }}
          autoComplete="bday"
          views={["date", "month", "year"]}
          label="Geburtsdatum"
          onError={(e: any) =>
            (e === "")
              ? setBirthDateError(false)
              : setBirthDateError(true)
          }
        />
        <div className={classes.addressWrapper}>
          <TextField
            className={classes.wideCell}
            autoComplete="street-address"
            label="Straße + Hausnummer"
            onChange={e => setAddressStreet(e.target.value)}
            value={addressStreet}
          />
          <TextField
            autoComplete="postal-code"
            label="Postleitzahl"
            onChange={e => setAddressZIP(e.target.value)}
            value={addressZIP}
          />
          <TextField
            autoComplete="address-level2"
            label="Stadt"
            onChange={e => setAddressTown(e.target.value)}
            value={addressTown}
          />
        </div>
        <TextField
          label="Steuernummer"
          onChange={e => setTaxID(e.target.value)}
          value={taxID}
        />
        {
          props.createNew
            ? undefined
            : editFields
        }
      </FormDialog>
      {
        props.createNew
          ? undefined
          : editDialogs
      }
    </>
  )
}