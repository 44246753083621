import { Button, makeStyles, TableBody, TableCell, TableHead } from "@material-ui/core"
import { LoadingDialog, FormDialog } from "../../customUI/dialogs"
import { DeleteIconRed, TableHeadCell, HoveredTableRow, StyledTable } from "../../customUI"
import { getHumanReadableHoliday, getHumanReadableHolidayDayAmounts, HolidayType } from "../../gql"
import * as Icons from '@material-ui/icons'
import { useConfirmDialog } from "../../ConfirmDialogProvider"
import { format } from "date-fns"
import { useDeleteHoliday } from "./deleteHoliday"
import BigNumber from "bignumber.js"
import { EditDialog } from "./EditDialog/EditDialog"
import { useState } from "react"
import { useError } from "../../Error/ErrorProvider"

type Holiday = {
	id: number
	type: HolidayType
	date: Date
	amountOfDay: BigNumber
	comment?: string
}

type Props = {
	open: boolean
	onClose: () => void
	onSubmit: () => void
	onEditBack: () => void
	data: {
		employeeName: string
		holidays: Holiday[]
	}
	refetchDate: Date
}

const useStyle = makeStyles((theme) => ({
	row: {
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.2)'
		},
	},
	rowSelected: {
		backgroundColor: theme.palette.secondary.light,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	smallButtonCell: {
		padding: 0,
	},
}))

export function SelectDialog(props: Props) {
	const classes = useStyle()
	const confirmDialog = useConfirmDialog()
	const errorHandler = useError()

	const [editDialogOpen, setEditDialogOpen] = useState(false)
	const [selectedHolidayIndex, setSelectedHolidayIndex] = useState(-1)

	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
	const deleteHoliday = useDeleteHoliday(props.refetchDate)

	if (props.data.holidays.length === 0) {
		props.onClose()
	}

	return <>
		<LoadingDialog open={loadingDialogOpen} />
		<FormDialog
			open={props.open}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
			data={{
				title: "Urlaub bearbeiten oder erstellen",
				submitButtonText: "Neuen Urlaub erstellen",
			}}
			style={{
				gridTemplateColumns: 'auto',
			}}
		>
			<StyledTable>
				<TableHead>
					<HoveredTableRow>
						<TableHeadCell
						>Typ</TableHeadCell>
						<TableHeadCell
						>Kontingent</TableHeadCell>
						<TableHeadCell
						>Kommentar</TableHeadCell>
						<TableHeadCell></TableHeadCell>
						<TableHeadCell></TableHeadCell>
					</HoveredTableRow>
				</TableHead>
				<TableBody>
					{props.data.holidays.map((holiday, index) => {
						const comment = ((holiday.comment?.length ?? 0) > 20)
							? holiday.comment?.slice(0, 18) + "..."
							: holiday.comment

						return (
							<HoveredTableRow
								key={holiday.id}
							>
								<TableCell
								>{getHumanReadableHoliday(holiday.type)}</TableCell>
								<TableCell
									align="right"
								>{getHumanReadableHolidayDayAmounts(holiday.amountOfDay)}</TableCell>
								<TableCell
								>{comment}</TableCell>
								<TableCell
									className={classes.smallButtonCell}
								>
									<Button
										onClick={() => {
											setSelectedHolidayIndex(index)
											setEditDialogOpen(true)
											props.onClose()
										}}
									>
										<Icons.Edit />
									</Button>
								</TableCell>
								<TableCell
									className={classes.smallButtonCell}
								>
									<Button
										onClick={() => {
											confirmDialog?.openWithContent({
												title: "Urlaub löschen?",
												content: <div>
													Wollen sie den Urlaub vom {format(holiday.date, "MM.dd.yyyy")} wirklich löschen?
												</div>,
												submitFunction: async () => {
													setLoadingDialogOpen(true)
													const result = await deleteHoliday(holiday.id)
													setLoadingDialogOpen(false)
													if (result.isError()) {
														errorHandler.pushError(result.error as Error)
													}
												},
											})
										}}
									>
										<DeleteIconRed />
									</Button>
								</TableCell>
							</HoveredTableRow>
						)
					})}
				</TableBody>
			</StyledTable>
		</FormDialog>
		<EditDialog
			key={props.data.holidays[selectedHolidayIndex]?.id}
			open={editDialogOpen}
			onClose={() => setEditDialogOpen(false)}
			onBack={props.onEditBack}
			data={{
				...props.data.holidays[selectedHolidayIndex],
				employeeName: props.data.employeeName,
			}}
			refetchDate={props.refetchDate}
		/>
	</>
}