import { gql, useMutation } from "@apollo/client";
import { Maybe } from "../../maybe";
import { refetchGetEmployeesQuery } from "../getEmployees";

const deleteHolidayMutation = gql`
	mutation deleteHoliday(
		$id: ID!
	) {
		holiday_delete(
			holiday_id: $id
		)
	}
`

type Params = {
  id: string
}

type Result = {
  holiday_delete: boolean
}

export function useDeleteHoliday(refetchDate: Date):
  (holidayID: number) => Promise<Maybe<boolean>> {
    const [mutation] = useMutation<Result, Params>(
      deleteHolidayMutation,
    )

    return async (holidayID: number) => {
      try {
        const mutationResult = await mutation({
          variables: {
            id: holidayID + "",
          },
          refetchQueries: [refetchGetEmployeesQuery(refetchDate)]
        })
        if (mutationResult.data != null) {
          return Maybe.Just(true)
        }
        return Maybe.None()
      } catch(e: any) {
        return Maybe.Error(e)
      }
    }
  }