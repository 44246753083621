export type Minutes = number | null

export type TargetMinutesGQL = {
  monday?: Minutes
  tuesday?: Minutes
  wednesday?: Minutes
  thursday?: Minutes
  friday?: Minutes
  saturday?: Minutes
  sunday?: Minutes
}

export type SanatizedTargetMinutesGQL = {
  monday?: number
  tuesday?: number
  wednesday?: number
  thursday?: number
  friday?: number
  saturday?: number
  sunday?: number
}

export type TargetMinutesStringArray = [string, string, string, string, string, string, string]
export type TargetMinutesArray = [Minutes, Minutes, Minutes, Minutes, Minutes, Minutes, Minutes]

export const dayStrings = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const
export const dayNumbers = [0, 1, 2, 3, 4, 5, 6] as const
export type DayStrings = typeof dayStrings[number]
export type DayNumbers = typeof dayNumbers[number]
export type Days = DayStrings | DayNumbers

export class TargetMinutes {
  public static get(array: TargetMinutesArray, index: Days): Minutes {
    if (typeof index === 'number') {
      return array[index]
    } else {
      return array[dayStrings.indexOf(index)]
    }
  }

  public static set(array: TargetMinutesArray, index: Days, value: Minutes) {
    if (typeof index === 'number') {
      array[index] = value
    } else {
      array[dayStrings.indexOf(index)] = value
    }
  }

  public static parseHourString(input: string): Minutes | undefined {
    if (!input.match(/\d\d:[0-5]\d/)) {
      return undefined
    }
    const parts = input.split(':')
    return parseInt(parts[0]) * 60 + parseInt(parts[1])
  }

  public static toHourString(input: Minutes): string {
    if (input === null) {
      return '00:00'
    }
    const hours = Math.floor(input / 60).toString().padStart(2, '0')
    const minutes = (input % 60).toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  public static getTotalMinutes(array: TargetMinutesArray): number {
    return array.reduce<number>(
      (prev, curr) => prev + (curr ?? 0),
      0
    )
  }

  public static toGQL(array: TargetMinutesArray): TargetMinutesGQL {
    return {
      monday: array[0],
      tuesday: array[1],
      wednesday: array[2],
      thursday: array[3],
      friday: array[4],
      saturday: array[5],
      sunday: array[6],
    }
  }

  public static sanatizeGQL(array: TargetMinutesGQL): SanatizedTargetMinutesGQL {
    return {
      monday: array.monday ?? undefined,
      tuesday: array.tuesday ?? undefined,
      wednesday: array.wednesday ?? undefined,
      thursday: array.thursday ?? undefined,
      friday: array.friday ?? undefined,
      saturday: array.saturday ?? undefined,
      sunday: array.sunday ?? undefined,
    }
  }

  public static fromGQL(input: TargetMinutesGQL): TargetMinutesArray {
    return dayStrings.map(day => input[day] ?? null) as TargetMinutesArray
  }

  public static toStringArray(array: TargetMinutesArray): TargetMinutesStringArray {
    return dayNumbers.map(
      n => TargetMinutes.toHourString(array[n])
    ) as TargetMinutesStringArray
  }

  public static fromStringArray(input: TargetMinutesStringArray): TargetMinutesArray {
    return dayNumbers.map(
      n => TargetMinutes.parseHourString(input[n]) ?? null
    ) as TargetMinutesArray
  }
}