import { gql, useMutation } from "@apollo/client";
import { Maybe } from "../../maybe";
import { refetchGetDetailsTimesViewQuery } from "../getDetails";

const editEventMutation = gql`
	mutation editEvent(
		$eventID: ID!,
		$newDate: DateTime!
	) {
		event_edit(
			event_id: $eventID,
			occured_at: $newDate,
		) {
			id
		}
	}
`

type Params = {
	eventID: string
	newDate: Date
}

type Result = {
	event_edit: { id: string }
}

type HookParams = Params & {
	employeeUUID: string
	month: Date
}

export function useEditEvent():
	(data: HookParams) => Promise<Maybe<boolean>> {
	const [mutation] = useMutation<Result, Params>(
		editEventMutation,
	)

	return async (data: HookParams) => {
		console.log('execute Mutation')
		try {
			const mutationResult = await mutation({
				variables: data,
				refetchQueries: [refetchGetDetailsTimesViewQuery(
					data.employeeUUID,
					data.month
				)],
			})
			if (mutationResult.data != null) {
				return Maybe.Just(true)
			}
			return Maybe.None()
		} catch (e: any) {
			return Maybe.Error(e)
		}
	}
}