import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
  caption: {
    color: theme.palette.text.hint,
  },
  underline: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.hint,
  },
}))

type Props = {
  classNames?: string | string[]
  caption: string
  underline?: boolean
  children: any
}

export function Caption(props: Props): JSX.Element {
  const classes = useStyle()
  return (
    <div className={clsx(
      props.classNames,
      { [classes.underline]: props.underline }
    )}>
      <Typography
        className={classes.caption}
        variant="caption"
      >{props.caption}</Typography>
      <Typography>{props.children}</Typography>
    </div>
  )
}