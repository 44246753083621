import { eachWeekendOfMonth, getDate, getDaysInMonth, getMonth, getYear } from "date-fns"
import { endOfMonth, startOfMonth } from "date-fns/fp"

export class MonthUtil {
	date: Date
	daysOfMonth: number[]
	daysWeekend: number[]

	constructor(date: Date) {
		this.date = date
		this.daysOfMonth = Array.from(Array(getDaysInMonth(date)).keys())
		this.daysWeekend = eachWeekendOfMonth(date).map(day => getDate(day))
	}

	getStartOfMonthUTC(): Date {
		const start = startOfMonth(this.date)
		return new Date(Date.UTC(getYear(start), getMonth(start), getDate(start)))
	}

	getEndOfMonthUTC(): Date {
		const end = endOfMonth(this.date)
		return new Date(Date.UTC(getYear(end), getMonth(end), getDate(end)))
	}

	isWeekend(day: Date | number): boolean {
		if (typeof day === 'number') {
			return this.daysWeekend.indexOf(day) !== -1
		} else {
			return this.daysWeekend.indexOf(getDate(day)) !== -1
		}
	}

	getMonthDateByDay(day: number): Date {
		return new Date(getYear(this.date), getMonth(this.date), day)
	}
}