import { Button, Dialog, DialogActions, DialogTitle, makeStyles, PropTypes } from "@material-ui/core"
import clsx from "clsx"
import "../../customCSS/scrollbar.css"

export type formDialogData = {
	title: string
	backButtonText?: string
	submitButtonText: string
	extraButtons?: JSX.Element[]
}

export type formDialogStyle = {
	title?: string | string[]
	gridTemplateColumns?: string
	backButton?: string | string[]
	submitButton?: string | string[]
	buttonColor?: PropTypes.Color
}

export type Props = {
	open: boolean
	onClose: () => void
	onBack?: () => void
	onSubmit: (() => Promise<void>) | (() => void)
	submitDisabled?: boolean
	data: formDialogData
	style?: formDialogStyle
	children: any
}

const useStyle = makeStyles(theme => ({
	header: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	mainContent: {
		display: 'grid',
		gap: theme.spacing(2),
		padding: theme.spacing(2),
	},
	dialogActions: {
		padding: theme.spacing(2),
	},
}))

export function FormDialog(props: Props) {
	const classes = useStyle()

	return (
		<Dialog fullWidth open={props.open} onBackdropClick={props.onClose}>
			<DialogTitle className={clsx(classes.header, props.style?.title)}>
				{props.data.title}
			</DialogTitle>
			<div
				style={{
					gridTemplateColumns: (props.style?.gridTemplateColumns ?? '1fr 1fr')
				}}
				className={clsx(classes.mainContent,  "scrollbar", "scrollbar-overlay")}
			>{props.children}
			</div>
			<DialogActions
				className={classes.dialogActions}
			>
				<Button
					color={props.style?.buttonColor ?? "primary"}
					onClick={() => {
						props.onBack?.()
						props.onClose()
					}}
					className={clsx(props.style?.backButton)}
				>{props.data.backButtonText ?? "zurück"}</Button>
				{props.data.extraButtons}
				<Button
					disabled={props.submitDisabled}
					variant="contained"
					color={props.style?.buttonColor ?? "primary"}
					onClick={async () => {
						await props.onSubmit()
						props.onClose()
					}}
					className={clsx(props.style?.submitButton)}
				>{props.data.submitButtonText}</Button>
			</DialogActions>
		</Dialog>
	)
}