type TitleProps = {
  title: string
  subtitle?: string
}

export function Title(props: TitleProps) {
  return <div style={{
    margin: 'auto',
    textAlign: 'center'
  }}>
    <h1 style={{
      margin: '0'
    }}>{props.title}</h1>
    {props.subtitle !== undefined
      ? <h4 style={{
        marginTop: '2mm'
      }}>{props.subtitle}</h4>
      : undefined}
  </div>
}