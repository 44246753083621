import useTitle from "../TitleProvider";
import { Button, DialogActions, makeStyles, TableBody, TableCell, TableHead } from "@material-ui/core";
import * as Icons from '@material-ui/icons';
import { useState } from "react";
import { EditDialog } from "./EditDialog/EditDialog";
import { LoadingDialog, DeleteIconRed, StyledTable, HoveredTableRow, TableHeadCell } from "../customUI";
import { useDeleteEmployee } from "./deleteEmployee";
import { useConfirmDialog } from "../ConfirmDialogProvider";
import { grey } from "@material-ui/core/colors";
import { max } from "date-fns/esm";
import { minTime } from "date-fns";
import { MailTo, StaffNumber } from "../customUI/formatting";
import { format } from "../util/dateFnsWrapper";
import { ParsedEmployee, useGetEmployees } from "./getEmployees";
import { DetailsDialog } from "./DetailsDialog";
import { LoadingError } from "../Error/LoadingError";
import { useError } from "../Error/ErrorProvider";

const useStyle = makeStyles(theme => ({
  tableContainer: {
    width: 'max-content',
  },
  detailsHeadCell: {
    textAlign: 'center',
  },
  greyedCell: {
    color: grey[400],
  },
  lightLink: {
    color: theme.palette.primary.light,
  },
}))

const dummyEmployee: ParsedEmployee = {
  isActive: false,
  uuid: "",
  name: { first: "", last: "" },
  mail: "",
  nfcTag: undefined,
  staffNumber: undefined,
  birthDate: undefined,
  taxID: undefined,
  address: undefined,
  employmentPeriods: [],
}

export function Employees() {
  const confirmDialog = useConfirmDialog()
  const classes = useStyle()
  const errorHandler = useError()

  const title = useTitle()
  title?.setTitle('Angestellte')

  const [employeeUUID, setEmployeeUUID] = useState("")

  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const deleteEmployee = useDeleteEmployee()

  const formatEmployees = (employees: ParsedEmployee[]): JSX.Element[] => {
    return employees
      .map(employee => {
        const className = employee.isActive
          ? undefined
          : classes.greyedCell
        const classNameLink = employee.isActive
          ? undefined
          : classes.lightLink
        const leavingDate = (employee.employmentPeriods.length > 0)
          ? format(
            employee.employmentPeriods.reduce(
              (maximum, curr) => max([maximum, curr.end]),
              new Date(minTime)
            ),
            "dd.MM.yyyy",
          )
          : undefined
        return (
          <HoveredTableRow key={employee.uuid}>
            <TableCell className={className}>
              <Button
                onClick={() => {
                  setDetailsDialogOpen(true)
                  setEmployeeUUID(employee.uuid)
                }}
              ><Icons.Visibility /></Button>
            </TableCell>
            <TableCell className={className} align="right"><StaffNumber number={employee.staffNumber} /></TableCell>
            <TableCell className={className}>{employee.name.first}</TableCell>
            <TableCell className={className}>{employee.name.last}</TableCell>
            <TableCell className={className}>
              <MailTo
                classNames={classNameLink}
                mail={employee.mail}
              />
            </TableCell>
            <TableCell className={className} align="right">{leavingDate}</TableCell>
            <TableCell>
              <Button onClick={() => {
                setEditDialogOpen(true)
                setEmployeeUUID(employee.uuid)
              }}>
                <Icons.Create />
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={() => {
                confirmDialog?.openWithContent({
                  title: "Mitarbeiter löschen?",
                  content:
                    <div>
                      Wollen sie wirklich Mitarbeiter
                      <strong> {employee.name.first} {employee.name.last} </strong>
                      löschen?
                    </div>,
                  submitFunction: async () => {
                    setLoadingDialogOpen(true)
                    const result = await deleteEmployee({ id: employee.uuid })
                    setLoadingDialogOpen(false)
                    if (result.isError()) {
                      errorHandler.pushError(result.error as Error)
                    }
                  },
                })
              }}>
                <DeleteIconRed />
              </Button>
            </TableCell>
          </HoveredTableRow>
        )
      })
  }

  const employees = useGetEmployees()
  if (employees.data.isError()) {
    return (
      <LoadingError
        error={employees.data.error as Error}
        onReload={() => employees.refetchQuery()}
      />
    )
  }
  const foundEmployee = (employees.data.value ?? []).find(employee => employee.uuid === employeeUUID)
  const selectedEmployee = foundEmployee ?? dummyEmployee

  return (
    <>
      <LoadingDialog open={employees.isLoading() || loadingDialogOpen} />
      <StyledTable>
        <TableHead>
          <HoveredTableRow>
            <TableHeadCell className={classes.detailsHeadCell}>Details</TableHeadCell>
            <TableHeadCell>Mitarbeiternummer</TableHeadCell>
            <TableHeadCell>Vorname</TableHeadCell>
            <TableHeadCell>Nachname</TableHeadCell>
            <TableHeadCell>Mailadresse</TableHeadCell>
            <TableHeadCell>Austrittsdatum</TableHeadCell>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell></TableHeadCell>
          </HoveredTableRow>
        </TableHead>
        <TableBody>
          {formatEmployees(employees.data.value ?? [])}
        </TableBody>
      </StyledTable>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEmployeeUUID("")
            setEditDialogOpen(true)
          }}
        >Neuen Angestellten anlegen</Button>
      </DialogActions>
      <DetailsDialog
        key={`details-${selectedEmployee?.uuid}`}
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        employee={selectedEmployee}
      />
      <EditDialog
        key={`edit-${selectedEmployee?.uuid}`}
        createNew={employeeUUID === ""}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        employee={selectedEmployee}
      />
    </>
  )
}