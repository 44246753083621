import { gql, useMutation } from "@apollo/client";
import { Maybe } from "../../maybe";
import { refetchGetEmployeesQuery } from "../getEmployees";

const editEmployeeMutation = gql`
	mutation edit(
		$id: ID!,
		$firstName: String,
		$lastName: String,
		$mail: String,
		$nfcTag: String,
    $staffNumber: Int,
    $birthDate: DateTime,
    $taxID: String,
    $address: employee_address_input
	) {
		employee_edit(
			employee_id: $id,
			first_name: $firstName,
			last_name: $lastName,
			mail: $mail,
			key: $nfcTag,
      additional_info: {
        staff_number: $staffNumber,
        birth_date: $birthDate,
        tax_id: $taxID
        address: $address
      }
		) {
			id
		}
	}`

type Params = {
  id: string
  firstName?: string
  lastName?: string
  mail?: string
  nfcTag?: string | null
  staffNumber?: number | null
  birthDate?: Date | null
  taxID?: string | null
  address?: {
    street: string
    zip_code: string
    town: string
  } | null
}

type Result = {
  employee_edit: {
    id: string
  }
}

export function useEditEmployee(): (data: Params) => Promise<Maybe<boolean>> {
  const [mutation] = useMutation<Result, Params>(
    editEmployeeMutation,
  )

  return async (data: Params) => {
    try {
      const mutationResult = await mutation({
        variables: data,
        refetchQueries: [refetchGetEmployeesQuery],
      })
      if (mutationResult.data != null) {
        return Maybe.Just(true)
      }
      return Maybe.None()
    } catch (e: any) {
      return Maybe.Error(e)
    }
  }
}