import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import BigNumber from "bignumber.js";
import { Maybe } from "../../../maybe";
import { refetchGetEmployeesQuery } from "../../getEmployees";

const createEmploymentPeriodMutation = gql`
  mutation createEmploymentPeriod(
    $id: ID!,
    $start: DateTime!,
    $end: DateTime!,
    $targetMinutes: employment_period_target_minutes_input!,
    $loan: Decimal!,
    $holidays: Int!
  ) {
    employment_period_create(
      employee_id: $id,
      period_start: $start,
      period_end: $end,
      target_minutes: $targetMinutes,
      loan: $loan,
      due_holidays: $holidays
    ) {
      id
    }
  }
`

type Params = {
  id: string
  start: Date
  end: Date
  targetMinutes: {
    monday?: number
    tuesday?: number
    wednesday?: number
    thursday?: number
    friday?: number
    saturday?: number
    sunday?: number
  }
  loan: BigNumber
  holidays: number
}

type Result = {
  employment_period_create: {
    id: string
  }
}

// type EmploymentPeriod = {
//  id: number
//  employeeUUID: string
//  periodStart: Date
//  periodEnd: Date
//  targetWeekMinutes: number
//  targetMinutes: {
//    monday?: number
//    tuesday?: number
//    wednesday?: number
//    thursday?: number
//    friday?: number
//    saturday?: number
//    sunday?: number
//  }
//  loan: BigNumber
// }

export function useCreateEmploymentPeriod(
  options?: MutationHookOptions<Result, Params>
): (data: Params) => Promise<Maybe<string>> {
  const [mutation] = useMutation<Result, Params>(
    createEmploymentPeriodMutation,
    options,
  )

  return async (data: Params) => {
    try {
      const mutationResult = await mutation({
        variables: data,
        refetchQueries: [refetchGetEmployeesQuery],
      })
      if (mutationResult.data != null) {
        return Maybe.Just(mutationResult.data.employment_period_create.id)
      }
      return Maybe.None()
    } catch (e: any) {
      return Maybe.Error(e)
    }
  }
}