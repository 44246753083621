import { useEffect } from "react";

export function useRegisterKeyboardInput(key: string, event: () => void) {
	const keyboardInput = (keyboardEvent: KeyboardEvent) => {
		if (keyboardEvent.key === key) {
			event()
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", keyboardInput)
		return () => {
			document.removeEventListener("keydown", keyboardInput)
		}
	})
}