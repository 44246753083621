import { ApolloError } from "@apollo/client"
import { format } from "../util/dateFnsWrapper"
import { getInvalidParameters, hasGQLErrorExtension } from "./gqlErrorUtil"

export type ParsedError = {
  title: string
  content: JSX.Element
  details?: {
    title: string
    content: JSX.Element
  }
}

export function parseError(e: Error | ApolloError): ParsedError[] {
  const result: ParsedError[] = []
  if (e instanceof ApolloError) {
    if (e.networkError != null) {
      result.push({
        title: "Netzwerkfehler",
        content: <div>
          Die Verbindung konnte nicht hergestellt werden.
        </div>,
      })
    }
    if (hasGQLErrorExtension(e, 'InvalidMail')) {
      result.push({
        title: "Ungültige Mail",
        content: <div>
          Die angegebene Mail ist ungültig.
        </div>
      })
    }
    if (hasGQLErrorExtension(e, 'InvalidPassword')) {
      result.push({
        title: "Ungültiges Passwort",
        content: <div>
          Das angegebene Passwort ist falsch.
        </div>
      })
    }
    if (hasGQLErrorExtension(e, 'InvalidKey')) {
      result.push({
        title: "ungültes NFC-Tag",
        content: <div>
          Das angegebene NFC-Tag hat kein gültiges Format.
        </div>
      })
    }
    if (hasGQLErrorExtension(e, 'InvalidParameter')) {
      const params = getInvalidParameters(e)
      let title: string
      let content: JSX.Element
      params.parameters.forEach(entry => {
        console.log(entry)
        switch (entry.errorName) {
          case 'InvalidInterval':
            title = 'Ungültiges Interval'
            content = <div>{`Das angegebene Interval [${format(new Date(entry.value[0]), "mm:HH - dd.MM.yyyy")
              }, ${format(new Date(entry.value[1]), "mm:HH - dd.MM.yyyy")
              }] ist ungültig oder überschneidet sich mit einem anderen Interval.`}</div>
            break
          default:
            title = entry.errorName
            content = <div>{entry.errorMessage}</div>
        }
        result.push({ title, content, })
      })
    }
    if (hasGQLErrorExtension(e, 'NotLoggedIn')) {
      const loggedIn = JSON.parse(localStorage.getItem("loggedIn") ?? "null")
      if (loggedIn === true) {
        localStorage.setItem("loggedIn", JSON.stringify(false))
      }
      result.push({
        title: "Nicht eingeloggt",
        content: <div>
          Für die gewünschte Aktion musst du eingeloggt sein.
        </div>
      })
    }
  }
  if (result.length === 0) {
    result.push({
      title: "Unbekannter Fehler",
      content: <div>
        Der Fehler konnte nicht abgefangen werden.
      </div>,
      details: {
        title: "Details",
        content: <>{e.message}</>,
      }
    })
  }
  return result
}