import { Button } from "@material-ui/core";
import { useRef } from 'react'
import ReactDOM from 'react-dom'
import { format } from "../util/dateFnsWrapper";
import { useError } from "../Error/ErrorProvider";
import { PrintInformation, useGetPrintInformation } from "./getPrintInformation";
import { Maybe } from "../maybe";
import { Currency, Interval } from "../customUI";
import BigNumber from "bignumber.js";
import { Section, SubSection, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow, Title } from "../customUI/printing";
import React from 'react'
import { PageA4 } from '../customUI/printing'

type EmployeeParams = {
  employeeUUID?: string
  month: Date
}

type PageProps = {
  printInfo: Maybe<PrintInformation>
  month: Date
}

function Page(props: PageProps) {
  const data = props.printInfo.value
  //move the th, td style to their custom UI definition


  let dueMinutesTotal = new BigNumber(0)
  let actualMinutesTotal = new BigNumber(0)

  return (
    <PageA4>
      <Title
        title={`Auswertung ${format(props.month, "MMMM yyyy")}`}
        subtitle={`${data?.employee.name.last} ${data?.employee.name.first}`}
      />
      <Section
        title="Urlaube genommen"
      >
        {data?.holidays.length === 0
          ? 'Keine Urlaubstage genommen'
          : data?.holidays.map(
            (h, i) => `${i !== 0 ? ', ' : ''}${format(h.date, "dd.MM.yyyy")}`
          )}
      </Section>
      <Section
        title="Krankheitstage"
      >
        {data?.illness.length === 0
          ? 'Keine Krankheitstage vorhanden'
          : data?.illness.map(
            (h, i) => `${i !== 0 ? ', ' : ''}${format(h.date, "dd.MM.yyyy")}`
          )}
      </Section>
      <Section
        title="Arbeitsstunden"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell style={{ minWidth: '25mm' }}>Tag</TableHeadCell>
              <TableHeadCell style={{ minWidth: '15mm' }}>Soll</TableHeadCell>
              <TableHeadCell style={{ minWidth: '15mm' }}>Ist</TableHeadCell>
              <TableHeadCell style={{ minWidth: '15mm' }}>Saldo</TableHeadCell>
              <TableHeadCell>Details</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.workingHours.map(
              day => {
                dueMinutesTotal = dueMinutesTotal.plus(day.dueInMinutes)
                actualMinutesTotal = actualMinutesTotal.plus(day.actualInMinutes)
                const formattedDay = format(day.day, "EE, dd.MM")
                return <TableRow
                  key={`workingDay:${formattedDay}`}
                  shadedAt="even"
                >
                  <TableCell
                    align='center'
                  >{formattedDay}</TableCell>
                  <TableCell
                    align='right'
                  ><Interval minutes={day.dueInMinutes} /></TableCell>
                  <TableCell
                    align='right'
                  ><Interval minutes={day.actualInMinutes} /></TableCell>
                  <TableCell
                    align='right'
                  ><Interval
                      minutes={day.actualInMinutes.minus(day.dueInMinutes)}
                    /></TableCell>
                  <TableCell>{day.intervals.map(
                    (int, idx) => {
                      let info = ''
                      if (typeof int === 'string') {
                        info = int
                      } else {
                        info = `${format(int.start, "HH:mm")} bis ${format(int.end, "HH:mm")}` 
                      }
                      return `${(idx !== 0) ? ', ' : ''} ${info}`
                    } 
                  )}</TableCell>
                </TableRow>
              }
            )}
            <TableRow shadedAt="even">
              <TableCell
                align='center'
                style={{ fontWeight: 'bold' }}
              >Gesamt</TableCell>
              <TableCell
                align='right'
              ><Interval minutes={dueMinutesTotal} /></TableCell>
              <TableCell
                align='right'
              ><Interval minutes={actualMinutesTotal} /></TableCell>
              <TableCell
                align='right'
              ><Interval minutes={actualMinutesTotal.minus(dueMinutesTotal)} /></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Section>
      <Section
        title="Auszahlung und Überstunden"
      >
        <SubSection
          title="Ausgezahlte Überstunden"
        >{data?.payouts.length === 0
          ? 'Keine Auszahlungenvorhanden'
          : data?.payouts
            .filter(p => p.type === 'OVERTIME_COMPENSATION')
            .map(
              (p, idx) => <React.Fragment key={`payout:${idx}`}>
                {idx !== 0 ? ', ' : ''}
                <Interval padLengthHours={0} minutes={p.valueInHours * 60} /> h
                (<Currency value={p.valueInEuro} />)
              </React.Fragment>
            )}</SubSection>
        <SubSection
          title="Restliche Überstunden"
        ><Interval minutes={data?.overtime ?? 0} /> h</SubSection>
        <SubSection
          title="Gesamte Auszahlung"
        ><Currency value={
          data?.payouts.reduce(
            (prev, curr) => prev.plus(curr.valueInEuro),
            new BigNumber(0)
          ) ?? 0
        } /></SubSection>
      </Section>
    </PageA4>
  )
}

export default function PrintView(props: EmployeeParams) {
  const errorHandler = useError()

  const printInformation = useGetPrintInformation({
    employeeID: props.employeeUUID ?? "",
    month: props.month
  })

  const frameRef = useRef<HTMLIFrameElement>(null)
  const print = () => {
    if (props.employeeUUID === undefined) {
      errorHandler.pushError(new Error("Ein Angestellter muss ausgewählt sein, um die Ansicht zu drucken"))
    } else {
      if (frameRef.current?.contentDocument != null) {
        ReactDOM.render(
          <Page
            printInfo={printInformation.data}
            month={props.month}
          />,
          frameRef.current.contentDocument.body,
          () => frameRef.current?.contentWindow?.print()
        )
      }
    }
  }

  return (
    <>
      <iframe title="printFrame" ref={frameRef} style={{ display: 'none' }} />
      <Button
        variant="contained"
        color="secondary"
        onClick={print}
      >Monatsübersicht drucken</Button>
    </>
  )
}