import { Maybe } from "../maybe";
import { QueryResult as ApolloQueryResult } from "@apollo/client";

export class QueryResult<Data, Result, RefetchParams> {
  readonly data: Maybe<Data>
  readonly refetchQuery: () => void
  private loading: boolean
  private skipped: boolean

  public constructor(
    result: ApolloQueryResult<Result, RefetchParams>,
    transform: (data: Result) => Data,
    options?: {
      refetchVariables?: RefetchParams
      skipCondition?: boolean
    }
  ) {
    this.loading = result.loading
    this.skipped = options?.skipCondition ?? false
    if (result.error) {
      this.data = Maybe.Error(result.error)
    } else if (this.skipped || this.loading) {
      this.data = Maybe.None()
    } else {
      const transformedData = transform(result.data as Result)
      this.data = Maybe.Just(transformedData)
    }
    this.refetchQuery = () => result.refetch(options?.refetchVariables)
  }

  public isResult(): boolean {
    return !(this.loading || this.skipped)
  }

  public isLoading(): boolean {
    return this.loading
  }

  public isSkipped(): boolean {
    return this.skipped
  }
}