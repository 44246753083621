import { Typography, Button, Dialog, DialogActions, DialogTitle, makeStyles } from "@material-ui/core"
import { createContext, useContext, useReducer } from "react"
import { parseError } from "./parseError"

const useStyle = makeStyles(theme => ({
	header: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	content: {
		display: 'grid',
		gridTemplateColumns: 'auto',
		gap: theme.spacing(2),
		margin: theme.spacing(2),
		marginTop: 0,
	},
	submitButton: {
		backgroundColor: theme.palette.error.main,
	},
}))

export type ErrorContextType = {
	pushError: (err: Error) => void
}

const ErrorContext = createContext<ErrorContextType>({
	pushError: (_) => { }
})

export function useError() {
	return useContext(ErrorContext)
}

type Action = {
	type: 'push' | 'pop'
	error: Error
}

function ErrorProvider(props: any) {
	const classes = useStyle()

	const [errors, dispatch] = useReducer(
		(state: Error[], action: Action) => {
			switch (action.type) {
				case 'push':
					console.log(state.length)
					if (state.indexOf(action.error) !== -1) {
						return state
					}
					return [...state, action.error]
				case 'pop':
					return state.filter(e => e !== action.error)
			}
		},
		[]
	)

	const ctx = {
		pushError: (err: Error) => dispatch({ type: 'push', error: err })
	}
	const onClose = (err: Error) => dispatch({ type: 'pop', error: err })

	return (
		<>
			<ErrorContext.Provider value={ctx}>
				{props.children}
			</ErrorContext.Provider>
			{
				errors.map((err, index) => parseError(err)
					.map((gqlErr, gqlIndex) => (
						<Dialog
							key={`error-${index}-${gqlIndex}`}
							open
							onClose={() => onClose(err)}
						>
							<DialogTitle className={classes.header}>
								{gqlErr.title}
							</DialogTitle>
							<div className={classes.content}>
								{gqlErr.content}
								{(gqlErr.details === undefined)
									? undefined
									: <>
										<Typography
											variant="h5"
										>{gqlErr.details.title}</Typography>
										{gqlErr.details.content}
									</>}
							</div>
							<DialogActions>
								<Button
									variant="contained"
									color="primary"
									onClick={() => onClose(err)}
								>Ok</Button>
							</DialogActions>
						</Dialog>
					))
				)
			}
		</>
	)
}

export default ErrorProvider