enum State {
  IsJust,
  IsNone,
  IsError
}

export class Maybe<T> {
  readonly value: T | undefined
  private state: State
  readonly error?: Error

  private constructor(
    value: T | undefined,
    state: State,
    error?: Error
  ) {
    this.value = value
    this.state = state
    this.error = error
  }

  public static Just<T>(value: T): Maybe<T> {
    return new Maybe(value, State.IsJust)
  }

  public static None<T>(): Maybe<T> {
    return new Maybe<T>(undefined, State.IsNone, undefined)
  }

  public static Error<T>(err: Error): Maybe<T> {
    return new Maybe<T>(undefined, State.IsError, err)
  }

  public isJust(): boolean {
    return this.state === State.IsJust
  }

  public isNone(): boolean {
    return this.state === State.IsNone
  }

  public isError(): boolean {
    return this.state === State.IsError
  }
}